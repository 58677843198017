import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback, ReactElement } from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { useGridConfig } from "./hooks/useGridConfig";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { useFetchServerSideGridData } from "../../api/hooks/useFetchServerSideGridData";
import TableLegend from "../../../../../components/table/TableLegend";
import _ from "lodash";
import { useGetTrackedRankingDataPromise } from "../../api/hooks/useGetTrackedRankingDataPromise";

interface DataTableProps {
  ipList: string;
  filterQuery: any;
  weightageValues: any;
  enabledWeightColumns: Array<string>;
  dateRange: any;
  additionalColumns: any;
  selectedAdditionalColumns: any;
  showLegend?: boolean;
  autoHeight?: boolean;
  usePagination?: boolean;
  onRowCountSet?: (count: number) => void;
  sortField?: string;
  sortOrder?: string;
}

export const DataTable = forwardRef<ReactElement | null, DataTableProps>(({
  ipList,
  filterQuery,
  weightageValues,
  enabledWeightColumns,
  dateRange,
  additionalColumns,
  selectedAdditionalColumns,
  showLegend=true,
  autoHeight=false,
  usePagination=true,
  onRowCountSet=null,
}, ref) => {

  const { theme } = useGetGridTheme();
  const gridRef = useRef(null);

  const [paginationPageSize, setPaginationPageSize] = useState(usePagination ? 100 : 1000);
  const [rowCount, setRowCount] = useState(0);

  const gridOptions = useGridConfig({
    enabledWeightColumns,
    additionalPlatformColumns: additionalColumns.map(o => o.value),
    selectedAdditionalColumns: selectedAdditionalColumns.map(o => o.value),
  });

  const { getTrackedRankingData } = useGetTrackedRankingDataPromise();

  useFetchServerSideGridData({
    filter: filterQuery,
    gridRef,
    ipList,
    fetchMethod: getTrackedRankingData,
    startTs: moment.utc(dateRange.startDate).startOf("day").unix(),
    endTs: moment.utc(dateRange.endDate).startOf("day").unix(),
    weights: weightageValues,
  } as any);

  return (
    <div className="flex flex-col space-y-4">
      <div style={{ width: "100%", height: (autoHeight && rowCount <= 20) ? "fit-content" : "80vh" }} className={theme}>
        <AgGridReact
          ref={gridRef}
          enableAdvancedFilter={true}
          domLayout={(autoHeight && rowCount <= 20) ? "autoHeight" : "normal"}
          paginationPageSize={paginationPageSize}
          pagination={usePagination}
          paginationPageSizeSelector={[20, 50, 100]}
          onPaginationChanged={(params) => {
            setRowCount(params.api.getDisplayedRowCount());
            if (onRowCountSet) {
              if (params.api.getServerSideGroupLevelState()?.[0]?.lastRowIndexKnown) {
                onRowCountSet(params.api.getDisplayedRowCount());
              } else {
                onRowCountSet(-1);
              }
            }
            const size = params.api.paginationGetPageSize();
            if (size !== paginationPageSize && usePagination) setPaginationPageSize(size);
          }}
          rowModelType={"serverSide"}
          blockLoadDebounceMillis={100}
          suppressRowClickSelection
          suppressCsvExport
          suppressExcelExport
          {...gridOptions}
        />
      </div>
      {showLegend && <TableLegend />}
    </div>
  );
});