import { FilterControlType, FilterJoinOperation, FilterObject, FilterOperation, FilterType } from "../../../types/filterTypes";
import { verticalOptionsWithAll } from "../../../types/verticalTypes";
import { DEFAULT_TRENDING_FILTERS } from "../../ranking/trending/constants";
import { getAllCategories } from "./utils";
import { SERVICES, TYPES } from "../../../config-global";
import { CATEGORIES as BASE_CATEGORIES, categoryOptions } from "../utils/categoryUtils";


const module = "google";
const TABS = {
  TRENDS: "trends",
};


export interface Country {
  label: string;
  value: string;
}

export const COUNTRIES: Record<string, Country> = {
  ARUBA: {
    label: "Aruba",
    value: "aw"
  },
  AFGHANISTAN: {
    label: "Afghanistan",
    value: "af"
  },
  ANGOLA: {
    label: "Angola",
    value: "ao"
  },
  ANGUILLA: {
    label: "Anguilla",
    value: "ai"
  },
  ALBANIA: {
    label: "Albania",
    value: "al"
  },
  ANDORRA: {
    label: "Andorra",
    value: "ad"
  },
  UNITED_ARAB_EMIRATES: {
    label: "United Arab Emirates",
    value: "ae"
  },
  ARGENTINA: {
    label: "Argentina",
    value: "ar"
  },
  ARMENIA: {
    label: "Armenia",
    value: "am"
  },
  AMERICAN_SAMOA: {
    label: "American Samoa",
    value: "as"
  },
  ANTARCTICA: {
    label: "Antarctica",
    value: "aq"
  },
  ASHMORE_AND_CARTIER_ISLANDS: {
    label: "Ashmore and Cartier Islands",
    value: "aq"
  },
  FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS: {
    label: "French Southern and Antarctic Lands",
    value: "tf"
  },
  ANTIGUA_AND_BARBUDA: {
    label: "Antigua and Barbuda",
    value: "ag"
  },
  AUSTRALIA: {
    label: "Australia",
    value: "au"
  },
  AUSTRIA: {
    label: "Austria",
    value: "at"
  },
  AZERBAIJAN: {
    label: "Azerbaijan",
    value: "az"
  },
  BURUNDI: {
    label: "Burundi",
    value: "bi"
  },
  BELGIUM: {
    label: "Belgium",
    value: "be"
  },
  BENIN: {
    label: "Benin",
    value: "bj"
  },
  BURKINA_FASO: {
    label: "Burkina Faso",
    value: "bf"
  },
  BANGLADESH: {
    label: "Bangladesh",
    value: "bd"
  },
  BULGARIA: {
    label: "Bulgaria",
    value: "bg"
  },
  BAHRAIN: {
    label: "Bahrain",
    value: "bh"
  },
  THE_BAHAMAS: {
    label: "The Bahamas",
    value: "bs"
  },
  BOSNIA_AND_HERZEGOVINA: {
    label: "Bosnia and Herzegovina",
    value: "ba"
  },
  BAJO_NUEVO_BANK: {
    label: "Bajo Nuevo Bank (Petrel Is.)",
    value: "um"
  },
  SAINT_BARTHELEMY: {
    label: "Saint Barthelemy",
    value: "bl"
  },
  BELARUS: {
    label: "Belarus",
    value: "by"
  },
  BELIZE: {
    label: "Belize",
    value: "bz"
  },
  BERMUDA: {
    label: "Bermuda",
    value: "bm"
  },
  BOLIVIA: {
    label: "Bolivia",
    value: "bo"
  },
  BRAZIL: {
    label: "Brazil",
    value: "br"
  },
  BARBADOS: {
    label: "Barbados",
    value: "bb"
  },
  BRUNEI: {
    label: "Brunei",
    value: "bn"
  },
  BHUTAN: {
    label: "Bhutan",
    value: "bt"
  },
  BOTSWANA: {
    label: "Botswana",
    value: "bw"
  },
  CENTRAL_AFRICAN_REPUBLIC: {
    label: "Central African Republic",
    value: "cf"
  },
  CANADA: {
    label: "Canada",
    value: "ca"
  },
  SWITZERLAND: {
    label: "Switzerland",
    value: "ch"
  },
  CHILE: {
    label: "Chile",
    value: "cl"
  },
  CHINA: {
    label: "China",
    value: "cn"
  },
  IVORY_COAST: {
    label: "Ivory Coast",
    value: "ci"
  },
  CAMEROON: {
    label: "Cameroon",
    value: "cm"
  },
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO: {
    label: "Democratic Republic of the Congo",
    value: "cd"
  },
  REPUBLIC_OF_CONGO: {
    label: "Republic of Congo",
    value: "cg"
  },
  COOK_ISLANDS: {
    label: "Cook Islands",
    value: "ck"
  },
  COLOMBIA: {
    label: "Colombia",
    value: "co"
  },
  COMOROS: {
    label: "Comoros",
    value: "km"
  },
  CAPE_VERDE: {
    label: "Cape Verde",
    value: "cv"
  },
  COSTA_RICA: {
    label: "Costa Rica",
    value: "cr"
  },
  CUBA: {
    label: "Cuba",
    value: "cu"
  },
  CURACAO: {
    label: "Curaçao",
    value: "cw"
  },
  CAYMAN_ISLANDS: {
    label: "Cayman Islands",
    value: "ky"
  },
  CYPRUS: {
    label: "Cyprus",
    value: "cy"
  },
  CZECH_REPUBLIC: {
    label: "Czech Republic",
    value: "cz"
  },
  GERMANY: {
    label: "Germany",
    value: "de"
  },
  DJIBOUTI: {
    label: "Djibouti",
    value: "dj"
  },
  DOMINICA: {
    label: "Dominica",
    value: "dm"
  },
  DENMARK: {
    label: "Denmark",
    value: "dk"
  },
  DOMINICAN_REPUBLIC: {
    label: "Dominican Republic",
    value: "do"
  },
  ALGERIA: {
    label: "Algeria",
    value: "dz"
  },
  ECUADOR: {
    label: "Ecuador",
    value: "ec"
  },
  EGYPT: {
    label: "Egypt",
    value: "eg"
  },
  ERITREA: {
    label: "Eritrea",
    value: "er"
  },
  SPAIN: {
    label: "Spain",
    value: "es"
  },
  ESTONIA: {
    label: "Estonia",
    value: "ee"
  },
  ETHIOPIA: {
    label: "Ethiopia",
    value: "et"
  },
  FINLAND: {
    label: "Finland",
    value: "fi"
  },
  FIJI: {
    label: "Fiji",
    value: "fj"
  },
  FALKLAND_ISLANDS: {
    label: "Falkland Islands",
    value: "fk"
  },
  FRANCE: {
    label: "France",
    value: "fr"
  },
  FAROE_ISLANDS: {
    label: "Faroe Islands",
    value: "fo"
  },
  FEDERATED_STATES_OF_MICRONESIA: {
    label: "Federated States of Micronesia",
    value: "fm"
  },
  GABON: {
    label: "Gabon",
    value: "ga"
  },
  UNITED_KINGDOM: {
    label: "United Kingdom",
    value: "gb"
  },
  GEORGIA: {
    label: "Georgia",
    value: "ge"
  },
  GUERNSEY: {
    label: "Guernsey",
    value: "gg"
  },
  GHANA: {
    label: "Ghana",
    value: "gh"
  },
  GIBRALTAR: {
    label: "Gibraltar",
    value: "gi"
  },
  GUINEA: {
    label: "Guinea",
    value: "gn"
  },
  GAMBIA: {
    label: "Gambia",
    value: "gm"
  },
  GUINEA_BISSAU: {
    label: "Guinea Bissau",
    value: "gw"
  },
  EQUATORIAL_GUINEA: {
    label: "Equatorial Guinea",
    value: "gq"
  },
  GREECE: {
    label: "Greece",
    value: "gr"
  },
  GRENADA: {
    label: "Grenada",
    value: "gd"
  },
  GREENLAND: {
    label: "Greenland",
    value: "gl"
  },
  GUATEMALA: {
    label: "Guatemala",
    value: "gt"
  },
  GUAM: {
    label: "Guam",
    value: "gu"
  },
  GUYANA: {
    label: "Guyana",
    value: "gy"
  },
  HONG_KONG: {
    label: "Hong Kong",
    value: "hk"
  },
  HONDURAS: {
    label: "Honduras",
    value: "hn"
  },
  CROATIA: {
    label: "Croatia",
    value: "hr"
  },
  HAITI: {
    label: "Haiti",
    value: "ht"
  },
  HUNGARY: {
    label: "Hungary",
    value: "hu"
  },
  INDONESIA: {
    label: "Indonesia",
    value: "id"
  },
  ISLE_OF_MAN: {
    label: "Isle of Man",
    value: "im"
  },
  INDIA: {
    label: "India",
    value: "in"
  },
  BRITISH_INDIAN_OCEAN_TERRITORY: {
    label: "British Indian Ocean Territory",
    value: "io"
  },
  IRELAND: {
    label: "Ireland",
    value: "ie"
  },
  IRAN: {
    label: "Iran",
    value: "ir"
  },
  IRAQ: {
    label: "Iraq",
    value: "iq"
  },
  ICELAND: {
    label: "Iceland",
    value: "is"
  },
  ISRAEL: {
    label: "Israel",
    value: "il"
  },
  ITALY: {
    label: "Italy",
    value: "it"
  },
  JAMAICA: {
    label: "Jamaica",
    value: "jm"
  },
  JERSEY: {
    label: "Jersey",
    value: "je"
  },
  JORDAN: {
    label: "Jordan",
    value: "jo"
  },
  JAPAN: {
    label: "Japan",
    value: "jp"
  },
  KAZAKHSTAN: {
    label: "Kazakhstan",
    value: "kz"
  },
  KENYA: {
    label: "Kenya",
    value: "ke"
  },
  KYRGYZSTAN: {
    label: "Kyrgyzstan",
    value: "kg"
  },
  CAMBODIA: {
    label: "Cambodia",
    value: "kh"
  },
  KIRIBATI: {
    label: "Kiribati",
    value: "ki"
  },
  SAINT_KITTS_AND_NEVIS: {
    label: "Saint Kitts and Nevis",
    value: "kn"
  },
  SOUTH_KOREA: {
    label: "South Korea",
    value: "kr"
  },
  KUWAIT: {
    label: "Kuwait",
    value: "kw"
  },
  LAOS: {
    label: "Laos",
    value: "la"
  },
  LEBANON: {
    label: "Lebanon",
    value: "lb"
  },
  LIBERIA: {
    label: "Liberia",
    value: "lr"
  },
  LIBYA: {
    label: "Libya",
    value: "ly"
  },
  SAINT_LUCIA: {
    label: "Saint Lucia",
    value: "lc"
  },
  LIECHTENSTEIN: {
    label: "Liechtenstein",
    value: "li"
  },
  SRI_LANKA: {
    label: "Sri Lanka",
    value: "lk"
  },
  LESOTHO: {
    label: "Lesotho",
    value: "ls"
  },
  LITHUANIA: {
    label: "Lithuania",
    value: "lt"
  },
  LUXEMBOURG: {
    label: "Luxembourg",
    value: "lu"
  },
  LATVIA: {
    label: "Latvia",
    value: "lv"
  },
  MACAO: {
    label: "Macao",
    value: "mo"
  },
  SAINT_MARTIN: {
    label: "Saint Martin",
    value: "mf"
  },
  MOROCCO: {
    label: "Morocco",
    value: "ma"
  },
  MONACO: {
    label: "Monaco",
    value: "mc"
  },
  MOLDOVA: {
    label: "Moldova",
    value: "md"
  },
  MADAGASCAR: {
    label: "Madagascar",
    value: "mg"
  },
  MALDIVES: {
    label: "Maldives",
    value: "mv"
  },
  MEXICO: {
    label: "Mexico",
    value: "mx"
  },
  MARSHALL_ISLANDS: {
    label: "Marshall Islands",
    value: "mh"
  },
  MACEDONIA: {
    label: "Macedonia",
    value: "mk"
  },
  MALI: {
    label: "Mali",
    value: "ml"
  },
  MALTA: {
    label: "Malta",
    value: "mt"
  },
  MYANMAR: {
    label: "Myanmar",
    value: "mm"
  },
  MONTENEGRO: {
    label: "Montenegro",
    value: "me"
  },
  MONGOLIA: {
    label: "Mongolia",
    value: "mn"
  },
  NORTHERN_MARIANA_ISLANDS: {
    label: "Northern Mariana Islands",
    value: "mp"
  },
  MOZAMBIQUE: {
    label: "Mozambique",
    value: "mz"
  },
  MAURITANIA: {
    label: "Mauritania",
    value: "mr"
  },
  MONTSERRAT: {
    label: "Montserrat",
    value: "ms"
  },
  MAURITIUS: {
    label: "Mauritius",
    value: "mu"
  },
  MALAWI: {
    label: "Malawi",
    value: "mw"
  },
  MALAYSIA: {
    label: "Malaysia",
    value: "my"
  },
  NAMIBIA: {
    label: "Namibia",
    value: "na"
  },
  NEW_CALEDONIA: {
    label: "New Caledonia",
    value: "nc"
  },
  NIGER: {
    label: "Niger",
    value: "ne"
  },
  NORFOLK_ISLAND: {
    label: "Norfolk Island",
    value: "nf"
  },
  NIGERIA: {
    label: "Nigeria",
    value: "ng"
  },
  NICARAGUA: {
    label: "Nicaragua",
    value: "ni"
  },
  NIUE: {
    label: "Niue",
    value: "nu"
  },
  NETHERLANDS: {
    label: "Netherlands",
    value: "nl"
  },
  NORWAY: {
    label: "Norway",
    value: "no"
  },
  NEPAL: {
    label: "Nepal",
    value: "np"
  },
  NAURU: {
    label: "Nauru",
    value: "nr"
  },
  NEW_ZEALAND: {
    label: "New Zealand",
    value: "nz"
  },
  OMAN: {
    label: "Oman",
    value: "om"
  },
  PAKISTAN: {
    label: "Pakistan",
    value: "pk"
  },
  PANAMA: {
    label: "Panama",
    value: "pa"
  },
  PITCAIRN_ISLANDS: {
    label: "Pitcairn Islands",
    value: "pn"
  },
  PERU: {
    label: "Peru",
    value: "pe"
  },
  PHILIPPINES: {
    label: "Philippines",
    value: "ph"
  },
  PALAU: {
    label: "Palau",
    value: "pw"
  },
  PAPUA_NEW_GUINEA: {
    label: "Papua New Guinea",
    value: "pg"
  },
  POLAND: {
    label: "Poland",
    value: "pl"
  },
  PUERTO_RICO: {
    label: "Puerto Rico",
    value: "pr"
  },
  NORTH_KOREA: {
    label: "North Korea",
    value: "kp"
  },
  PORTUGAL: {
    label: "Portugal",
    value: "pt"
  },
  PARAGUAY: {
    label: "Paraguay",
    value: "py"
  },
  PALESTINE: {
    label: "Palestine",
    value: "ps"
  },
  FRENCH_POLYNESIA: {
    label: "French Polynesia",
    value: "pf"
  },
  QATAR: {
    label: "Qatar",
    value: "qa"
  },
  ROMANIA: {
    label: "Romania",
    value: "ro"
  },
  RUSSIA: {
    label: "Russia",
    value: "ru"
  },
  RWANDA: {
    label: "Rwanda",
    value: "rw"
  },
  WESTERN_SAHARA: {
    label: "Western Sahara",
    value: "eh"
  },
  SAUDI_ARABIA: {
    label: "Saudi Arabia",
    value: "sa"
  },
  SUDAN: {
    label: "Sudan",
    value: "sd"
  },
  SOUTH_SUDAN: {
    label: "South Sudan",
    value: "ss"
  },
  SENEGAL: {
    label: "Senegal",
    value: "sn"
  },
  SINGAPORE: {
    label: "Singapore",
    value: "sg"
  },
  SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS: {
    label: "South Georgia and South Sandwich Islands",
    value: "gs"
  },
  SAINT_HELENA: {
    label: "Saint Helena",
    value: "sh"
  },
  SOLOMON_ISLANDS: {
    label: "Solomon Islands",
    value: "sb"
  },
  SIERRA_LEONE: {
    label: "Sierra Leone",
    value: "sl"
  },
  EL_SALVADOR: {
    label: "El Salvador",
    value: "sv"
  },
  SAN_MARINO: {
    label: "San Marino",
    value: "sm"
  },
  SOMALIA: {
    label: "Somalia",
    value: "so"
  },
  SAINT_PIERRE_AND_MIQUELON: {
    label: "Saint Pierre and Miquelon",
    value: "pm"
  },
  REPUBLIC_OF_SERBIA: {
    label: "Republic of Serbia",
    value: "rs"
  },
  SAO_TOME_AND_PRINCIPE: {
    label: "Sao Tome and Principe",
    value: "st"
  },
  SURINAME: {
    label: "Suriname",
    value: "sr"
  },
  SLOVAKIA: {
    label: "Slovakia",
    value: "sk"
  },
  SLOVENIA: {
    label: "Slovenia",
    value: "si"
  },
  SWEDEN: {
    label: "Sweden",
    value: "se"
  },
  SWAZILAND: {
    label: "Swaziland",
    value: "sz"
  },
  SINT_MAARTEN: {
    label: "Sint Maarten",
    value: "sx"
  },
  SEYCHELLES: {
    label: "Seychelles",
    value: "sc"
  },
  SYRIA: {
    label: "Syria",
    value: "sy"
  },
  TURKS_AND_CAICOS_ISLANDS: {
    label: "Turks and Caicos Islands",
    value: "tc"
  },
  CHAD: {
    label: "Chad",
    value: "td"
  },
  TOGO: {
    label: "Togo",
    value: "tg"
  },
  THAILAND: {
    label: "Thailand",
    value: "th"
  },
  TAJIKISTAN: {
    label: "Tajikistan",
    value: "tj"
  },
  TURKMENISTAN: {
    label: "Turkmenistan",
    value: "tm"
  },
  EAST_TIMOR: {
    label: "East Timor",
    value: "tl"
  },
  TONGA: {
    label: "Tonga",
    value: "to"
  },
  TRINIDAD_AND_TOBAGO: {
    label: "Trinidad and Tobago",
    value: "tt"
  },
  TUNISIA: {
    label: "Tunisia",
    value: "tn"
  },
  TURKEY: {
    label: "Turkey",
    value: "tr"
  },
  TUVALU: {
    label: "Tuvalu",
    value: "tv"
  },
  TAIWAN: {
    label: "Taiwan",
    value: "tw"
  },
  UNITED_REPUBLIC_OF_TANZANIA: {
    label: "United Republic of Tanzania",
    value: "tz"
  },
  UGANDA: {
    label: "Uganda",
    value: "ug"
  },
  UKRAINE: {
    label: "Ukraine",
    value: "ua"
  },
  UNITED_STATES_MINOR_OUTLYING_ISLANDS: {
    label: "United States Minor Outlying Islands",
    value: "um"
  },
  URUGUAY: {
    label: "Uruguay",
    value: "uy"
  },
  UNITED_STATES_OF_AMERICA: {
    label: "United States of America",
    value: "us"
  },
  UZBEKISTAN: {
    label: "Uzbekistan",
    value: "uz"
  },
  VATICAN: {
    label: "Vatican",
    value: "va"
  },
  SAINT_VINCENT_AND_THE_GRENADINES: {
    label: "Saint Vincent and the Grenadines",
    value: "vc"
  },
  VENEZUELA: {
    label: "Venezuela",
    value: "ve"
  },
  BRITISH_VIRGIN_ISLANDS: {
    label: "British Virgin Islands",
    value: "vg"
  },
  UNITED_STATES_VIRGIN_ISLANDS: {
    label: "United States Virgin Islands",
    value: "vi"
  },
  VIETNAM: {
    label: "Vietnam",
    value: "vn"
  },
  VANUATU: {
    label: "Vanuatu",
    value: "vu"
  },
  WALLIS_AND_FUTUNA: {
    label: "Wallis and Futuna",
    value: "wf"
  },
  SAMOA: {
    label: "Samoa",
    value: "ws"
  },
  YEMEN: {
    label: "Yemen",
    value: "ye"
  },
  SOUTH_AFRICA: {
    label: "South Africa",
    value: "za"
  },
  ZAMBIA: {
    label: "Zambia",
    value: "zm"
  },
  ZIMBABWE: {
    label: "Zimbabwe",
    value: "zw"
  }
};


export interface DMARegion {
  label: string;
  value: string;
}

export const DMA_REGIONS: Record<string, DMARegion> = {
  ABILENE_SWEETWATER_TX: {
    label: "Abilene-Sweetwater, TX",
    value: "abilene-sweetwater, tx"
  },
  ALBANY_GA: {
    label: "Albany, GA",
    value: "albany, ga"
  },
  ALBANY_SCHENECTADY_TROY_NY: {
    label: "Albany-Schenectady-Troy, NY",
    value: "albany-schenectady-troy, ny"
  },
  ALBUQUERQUE_SANTA_FE_NM: {
    label: "Albuquerque-Santa Fe, NM",
    value: "albuquerque-santa fe, nm"
  },
  ALEXANDRIA_LA: {
    label: "Alexandria, LA",
    value: "alexandria, la"
  },
  ALPENA_MI: {
    label: "Alpena, MI",
    value: "alpena, mi"
  },
  AMARILLO_TX: {
    label: "Amarillo, TX",
    value: "amarillo, tx"
  },
  ATLANTA_GA: {
    label: "Atlanta, GA",
    value: "atlanta, ga"
  },
  AUGUSTA_GA: {
    label: "Augusta, GA",
    value: "augusta, ga"
  },
  AUSTIN_TX: {
    label: "Austin, TX",
    value: "austin, tx"
  },
  BAKERSFIELD_CA: {
    label: "Bakersfield, CA",
    value: "bakersfield, ca"
  },
  BALTIMORE_MD: {
    label: "Baltimore, MD",
    value: "baltimore, md"
  },
  BANGOR_ME: {
    label: "Bangor, ME",
    value: "bangor, me"
  },
  BATON_ROUGE_LA: {
    label: "Baton Rouge, LA",
    value: "baton rouge, la"
  },
  BEAUMONT_PORT_ARTHUR_TX: {
    label: "Beaumont-Port Arthur, TX",
    value: "beaumont-port arthur, tx"
  },
  BEND_OR: {
    label: "Bend, OR",
    value: "bend, or"
  },
  BILLINGS_MT: {
    label: "Billings, MT",
    value: "billings, mt"
  },
  BILOXI_GULFPORT_MS: {
    label: "Biloxi-Gulfport, MS",
    value: "biloxi-gulfport, ms"
  },
  BINGHAMTON_NY: {
    label: "Binghamton, NY",
    value: "binghamton, ny"
  },
  BIRMINGHAM_ANNISTON_AND_TUSCALOOSA_AL: {
    label: "Birmingham (Anniston and Tuscaloosa), AL",
    value: "birmingham, al"
  },
  BLUEFIELD_BECKLEY_OAK_HILL_WV: {
    label: "Bluefield-Beckley-Oak Hill, WV",
    value: "bluefield-beckley-oak hill, wv"
  },
  BOISE_ID: {
    label: "Boise, ID",
    value: "boise, id"
  },
  BOSTON_MA_MANCHESTER_NH: {
    label: "Boston, MA (Manchester, NH)",
    value: "boston, ma-manchester, nh"
  },
  BOWLING_GREEN_KY: {
    label: "Bowling Green, KY",
    value: "bowling green, ky"
  },
  BUFFALO_NY: {
    label: "Buffalo, NY",
    value: "buffalo, ny"
  },
  BURLINGTON_VT_PLATTSBURGH_NY: {
    label: "Burlington, VT-Plattsburgh, NY",
    value: "burlington, vt-plattsburgh, ny"
  },
  BUTTE_BOZEMAN_MT: {
    label: "Butte-Bozeman, MT",
    value: "butte-bozeman, mt"
  },
  CASPER_RIVERTON_WY: {
    label: "Casper-Riverton, WY",
    value: "casper-riverton, wy"
  },
  CEDAR_RAPIDS_WATERLOO_IOWA_CITY_DUBUQUE_IA: {
    label: "Cedar Rapids-Waterloo-Iowa City & Dubuque, IA",
    value: "cedar rapids-waterloo-iowa city & dubuque, ia"
  },
  CHAMPAIGN_SPRINGFIELD_DECATUR_IL: {
    label: "Champaign & Springfield-Decatur, IL",
    value: "champaign & springfield-decatur,il"
  },
  CHARLESTON_SC: {
    label: "Charleston, SC",
    value: "charleston, sc"
  },
  CHARLESTON_HUNTINGTON_WV: {
    label: "Charleston-Huntington, WV",
    value: "charleston-huntington, wv"
  },
  CHARLOTTE_NC: {
    label: "Charlotte, NC",
    value: "charlotte, nc"
  },
  CHARLOTTESVILLE_VA: {
    label: "Charlottesville, VA",
    value: "charlottesville, va"
  },
  CHATTANOOGA_TN: {
    label: "Chattanooga, TN",
    value: "chattanooga, tn"
  },
  CHEYENNE_WY_SCOTTSBLUFF_NE: {
    label: "Cheyenne, WY-Scottsbluff, NE",
    value: "cheyenne, wy-scottsbluff, ne"
  },
  CHICAGO_IL: {
    label: "Chicago, IL",
    value: "chicago, il"
  },
  CHICO_REDDING_CA: {
    label: "Chico-Redding, CA",
    value: "chico-redding, ca"
  },
  CINCINNATI_OH: {
    label: "Cincinnati, OH",
    value: "cincinnati, oh"
  },
  CLARKSBURG_WESTON_WV: {
    label: "Clarksburg-Weston, WV",
    value: "clarksburg-weston, wv"
  },
  CLEVELAND_AKRON_CANTON_OH: {
    label: "Cleveland-Akron (Canton), OH",
    value: "cleveland-akron (canton), oh"
  },
  COLORADO_SPRINGS_PUEBLO_CO: {
    label: "Colorado Springs-Pueblo, CO",
    value: "colorado springs-pueblo, co"
  },
  COLUMBIA_SC: {
    label: "Columbia, SC",
    value: "columbia, sc"
  },
  COLUMBIA_JEFFERSON_CITY_MO: {
    label: "Columbia-Jefferson City, MO",
    value: "columbia-jefferson city, mo"
  },
  COLUMBUS_GA: {
    label: "Columbus, GA",
    value: "columbus, ga"
  },
  COLUMBUS_OH: {
    label: "Columbus, OH",
    value: "columbus, oh"
  },
  COLUMBUS_TUPELO_WEST_POINT_MS: {
    label: "Columbus-Tupelo-West Point, MS",
    value: "columbus-tupelo-west point, ms"
  },
  CORPUS_CHRISTI_TX: {
    label: "Corpus Christi, TX",
    value: "corpus christi, tx"
  },
  DALLAS_FT_WORTH_TX: {
    label: "Dallas-Ft. Worth, TX",
    value: "dallas-ft. worth, tx"
  },
  DAVENPORT_IA_ROCK_ISLAND_MOLINE_IL: {
    label: "Davenport, IA-Rock Island-Moline, IL",
    value: "davenport,ia-rock island-moline,il"
  },
  DAYTON_OH: {
    label: "Dayton, OH",
    value: "dayton, oh"
  },
  DENVER_CO: {
    label: "Denver, CO",
    value: "denver, co"
  },
  DES_MOINES_AMES_IA: {
    label: "Des Moines-Ames, IA",
    value: "des moines-ames, ia"
  },
  DETROIT_MI: {
    label: "Detroit, MI",
    value: "detroit, mi"
  },
  DOTHAN_AL: {
    label: "Dothan, AL",
    value: "dothan, al"
  },
  DULUTH_MN_SUPERIOR_WI: {
    label: "Duluth, MN-Superior, WI",
    value: "duluth, mn-superior, wi"
  },
  EL_PASO_TX: {
    label: "El Paso, TX",
    value: "el paso, tx"
  },
  ELMIRA_NY: {
    label: "Elmira, NY",
    value: "elmira, ny"
  },
  ERIE_PA: {
    label: "Erie, PA",
    value: "erie, pa"
  },
  EUGENE_OR: {
    label: "Eugene, OR",
    value: "eugene, or"
  },
  EUREKA_CA: {
    label: "Eureka, CA",
    value: "eureka, ca"
  },
  EVANSVILLE_IN: {
    label: "Evansville, IN",
    value: "evansville, in"
  },
  FARGO_VALLEY_CITY_ND: {
    label: "Fargo-Valley City, ND",
    value: "fargo-valley city, nd"
  },
  FLINT_SAGINAW_BAY_CITY_MI: {
    label: "Flint-Saginaw-Bay City, MI",
    value: "flint-saginaw-bay city, mi"
  },
  FRESNO_VISALIA_CA: {
    label: "Fresno-Visalia, CA",
    value: "fresno-visalia, ca"
  },
  FT_MYERS_NAPLES_FL: {
    label: "Ft. Myers-Naples, FL",
    value: "ft. myers-naples, fl"
  },
  FT_SMITH_FAYETTEVILLE_SPRINGDALE_ROGERS_AR: {
    label: "Ft. Smith-Fayetteville-Springdale-Rogers, AR",
    value: "ft. smith-fayetteville-springdale-rogers, ar"
  },
  FT_WAYNE_IN: {
    label: "Ft. Wayne, IN",
    value: "ft. wayne, in"
  },
  GAINESVILLE_FL: {
    label: "Gainesville, FL",
    value: "gainesville, fl"
  },
  GLENDIVE_MT: {
    label: "Glendive, MT",
    value: "glendive, mt"
  },
  GRAND_JUNCTION_MONTROSE_CO: {
    label: "Grand Junction-Montrose, CO",
    value: "grand junction-montrose, co"
  },
  GRAND_RAPIDS_KALAMAZOO_BATTLE_CREEK_MI: {
    label: "Grand Rapids-Kalamazoo-Battle Creek, MI",
    value: "grand rapids-kalamazoo-battle creek, mi"
  },
  GREAT_FALLS_MT: {
    label: "Great Falls, MT",
    value: "great falls, mt"
  },
  GREEN_BAY_APPLETON_WI: {
    label: "Green Bay-Appleton, WI",
    value: "green bay-appleton, wi"
  },
  GREENSBORO_HIGH_POINT_WINSTON_SALEM_NC: {
    label: "Greensboro-High Point-Winston Salem, NC",
    value: "greensboro-high point-winston salem, nc"
  },
  GREENVILLE_NEW_BERN_WASHINGTON_NC: {
    label: "Greenville-New Bern-Washington, NC",
    value: "greenville-new bern-washington, nc"
  },
  GREENVILLE_SPARTANBURG_SC_ASHEVILLE_NC_ANDERSONSC: {
    label: "Greenville-Spartanburg, SC-Asheville, NC-Anderson,SC",
    value: "greenville-spartanburg, sc-asheville, nc-anderson, sc"
  },
  GREENWOOD_GREENVILLE_MS: {
    label: "Greenwood-Greenville, MS",
    value: "greenwood-greenville, ms"
  },
  HARLINGEN_WESLACO_BROWNSVILLE_MCALLEN_TX: {
    label: "Harlingen-Weslaco-Brownsville-McAllen, TX",
    value: "harlingen-weslaco-brownsville-mcallen, tx"
  },
  HARRISBURG_LANCASTER_LEBANON_YORK_PA: {
    label: "Harrisburg-Lancaster-Lebanon-York, PA",
    value: "harrisburg-lancaster-lebanon-york, pa"
  },
  HARRISONBURG_VA: {
    label: "Harrisonburg, VA",
    value: "harrisonburg, va"
  },
  HARTFORD_NEW_HAVEN_CT: {
    label: "Hartford & New Haven, CT",
    value: "hartford & new haven, ct"
  },
  HATTIESBURG_LAUREL_MS: {
    label: "Hattiesburg-Laurel, MS",
    value: "hattiesburg-laurel, ms"
  },
  HELENA_MT: {
    label: "Helena, MT",
    value: "helena, mt"
  },
  HOUSTON_TX: {
    label: "Houston, TX",
    value: "houston, tx"
  },
  HUNTSVILLE_DECATUR_FLORENCE_AL: {
    label: "Huntsville-Decatur (Florence), AL",
    value: "huntsville-decatur (florence), al"
  },
  IDAHO_FALLS_POCATELLO_ID: {
    label: "Idaho Falls-Pocatello, ID",
    value: "idaho falls-pocatello, id"
  },
  INDIANAPOLIS_IN: {
    label: "Indianapolis, IN",
    value: "indianapolis, in"
  },
  JACKSON_MS: {
    label: "Jackson, MS",
    value: "jackson, ms"
  },
  JACKSON_TN: {
    label: "Jackson, TN",
    value: "jackson, tn"
  },
  JACKSONVILLE_FL: {
    label: "Jacksonville, FL",
    value: "jacksonville, fl"
  },
  JOHNSTOWN_ALTOONA_PA: {
    label: "Johnstown-Altoona, PA",
    value: "johnstown-altoona, pa"
  },
  JONESBORO_AR: {
    label: "Jonesboro, AR",
    value: "jonesboro, ar"
  },
  JOPLIN_MO_PITTSBURG_KS: {
    label: "Joplin, MO-Pittsburg, KS",
    value: "joplin, mo-pittsburg, ks"
  },
  KANSAS_CITY_MO: {
    label: "Kansas City, MO",
    value: "kansas city, mo"
  },
  KNOXVILLE_TN: {
    label: "Knoxville, TN",
    value: "knoxville, tn"
  },
  LA_CROSSE_EAU_CLAIRE_WI: {
    label: "La Crosse-Eau Claire, WI",
    value: "la crosse-eau claire, wi"
  },
  LAFAYETTE_IN: {
    label: "Lafayette, IN",
    value: "lafayette, in"
  },
  LAFAYETTE_LA: {
    label: "Lafayette, LA",
    value: "lafayette, la"
  },
  LAKE_CHARLES_LA: {
    label: "Lake Charles, LA",
    value: "lake charles, la"
  },
  LANSING_MI: {
    label: "Lansing, MI",
    value: "lansing, mi"
  },
  LARERO_TX: {
    label: "Laredo, TX",
    value: "laredo, tx"
  },
  LAS_VEGAS_NV: {
    label: "Las Vegas, NV",
    value: "las vegas, nv"
  },
  LEXINGTON_KY: {
    label: "Lexington, KY",
    value: "lexington, ky"
  },
  LIMA_OH: {
    label: "Lima, OH",
    value: "lima, oh"
  },
  LINCOLN_HASTINGS_KEARNEY_NE: {
    label: "Lincoln & Hastings-Kearney, NE",
    value: "lincoln & hastings-kearney, ne"
  },
  LITTLE_ROCK_PINE_BLUFF_AR: {
    label: "Little Rock-Pine Bluff, AR",
    value: "little rock-pine bluff, ar"
  },
  LOS_ANGELES_CA: {
    label: "Los Angeles, CA",
    value: "los angeles, ca"
  },
  LOUISVILLE_KY: {
    label: "Louisville, KY",
    value: "louisville, ky"
  },
  LUBBOCK_TX: {
    label: "Lubbock, TX",
    value: "lubbock, tx"
  },
  MACON_GA: {
    label: "Macon, GA",
    value: "macon, ga"
  },
  MADISON_WI: {
    label: "Madison, WI",
    value: "madison, wi"
  },
  MANKATO_MN: {
    label: "Mankato, MN",
    value: "mankato, mn"
  },
  MARQUETTE_MI: {
    label: "Marquette, MI",
    value: "marquette, mi"
  },
  MEDFORD_KLAMATH_FALLS_OR: {
    label: "Medford-Klamath Falls, OR",
    value: "medford-klamath falls, or"
  },
  MEMPHIS_TN: {
    label: "Memphis, TN",
    value: "memphis, tn"
  },
  MERIDIAN_MS: {
    label: "Meridian, MS",
    value: "meridian, ms"
  },
  MIAMI_FORT_LAUDERDALE_FL: {
    label: "Miami-Fort Lauderdale, FL",
    value: "miami-ft. lauderdale, fl"
  },
  MILWAUKEE_WI: {
    label: "Milwaukee, WI",
    value: "milwaukee, wi"
  },
  MINNEAPOLIS_ST_PAUL_MN: {
    label: "Minneapolis-St. Paul, MN",
    value: "minneapolis-st. paul, mn"
  },
  MINOT_BISMARCK_DICKINSON_ND: {
    label: "Minot-Bismarck-Dickinson(Williston), ND",
    value: "minot-bismarck-dickinson(williston), nd"
  },
  MISSOULA_MT: {
    label: "Missoula, MT",
    value: "missoula, mt"
  },
  MOBILE_AL_PENSACOLA_FT_WALTON_BEACH_FL: {
    label: "Mobile, AL-Pensacola (Ft. Walton Beach), FL",
    value: "mobile, al-pensacola (ft. walton beach), fl"
  },
  MONROE_LA_EL_DORADO_AR: {
    label: "Monroe, LA-El Dorado, AR",
    value: "monroe, la-el dorado, ar"
  },
  MONTEREY_SALINAS_CA: {
    label: "Monterey-Salinas, CA",
    value: "monterey-salinas, ca"
  },
  MONTGOMERY_SELMA_AL: {
    label: "Montgomery-Selma, AL",
    value: "montgomery (selma), al"
  },
  MYRTLE_BEACH_FLORENCE_SC: {
    label: "Myrtle Beach-Florence, SC",
    value: "florence-myrtle beach, sc"
  },
  NASHVILLE_TN: {
    label: "Nashville, TN",
    value: "nashville, tn"
  },
  NEW_ORLEANS_LA: {
    label: "New Orleans, LA",
    value: "new orleans, la"
  },
  NEW_YORK_NY: {
    label: "New York, NY",
    value: "new york, ny"
  },
  NORFOLK_PORTSMOUTH_NEWPORT_NEWS_VA: {
    label: "Norfolk-Portsmouth-Newport News, VA",
    value: "norfolk-portsmouth-newport news, va"
  },
  NORTH_PLATTE_NE: {
    label: "North Platte, NE",
    value: "north platte, ne"
  },
  ODESSA_MIDLAND_TX: {
    label: "Odessa-Midland, TX",
    value: "odessa-midland, tx"
  },
  OKLAHOMA_CITY_OK: {
    label: "Oklahoma City, OK",
    value: "oklahoma city, ok"
  },
  OMAHA_NE: {
    label: "Omaha, NE",
    value: "omaha, ne"
  },
  ORLANDO_DAYTONA_BEACH_MELBOURNE_FL: {
    label: "Orlando-Daytona Beach-Melbourne, FL",
    value: "orlando-daytona beach-melbourne, fl"
  },
  OTTUMWA_IA_KIRKSVILLE_MO: {
    label: "Ottumwa, IA-Kirksville, MO",
    value: "ottumwa, ia-kirksville, mo"
  },
  PADUCAH_KY_CAPE_GIRARDEAU_MO_HARRISBURG_IL: {
    label: "Paducah, KY-Cape Girardeau, MO-Harrisburg, IL",
    value: "paducah, ky-cape girardeau, mo-harrisburg-mount vernon, il"
  },
  PALM_SPRINGS_CA: {
    label: "Palm Springs, CA",
    value: "palm springs, ca"
  },
  PANAMA_CITY_FL: {
    label: "Panama City, FL",
    value: "panama city, fl"
  },
  PARKERSBURG_WV: {
    label: "Parkersburg, WV",
    value: "parkersburg, wv"
  },
  PEORIA_BLOOMINGTON_IL: {
    label: "Peoria-Bloomington, IL",
    value: "peoria-bloomington, il"
  },
  PHILADELPHIA_PA: {
    label: "Philadelphia, PA",
    value: "philadelphia, pa"
  },
  PHOENIX_AZ: {
    label: "Phoenix, AZ",
    value: "phoenix, az"
  },
  PITTSBURGH_PA: {
    label: "Pittsburgh, PA",
    value: "pittsburgh, pa"
  },
  PORTLAND_OR: {
    label: "Portland, OR",
    value: "portland, or"
  },
  PORTLAND_AUBURN_ME: {
    label: "Portland-Auburn, ME",
    value: "portland-auburn, me"
  },
  PRESQUE_ISLE_ME: {
    label: "Presque Isle, ME",
    value: "presque isle, me"
  },
  PROVIDENCE_RI_NEW_BEDFORD_MA: {
    label: "Providence, RI-New Bedford, MA",
    value: "providence, ri-new bedford, ma"
  },
  QUINCY_IL_HANNIBAL_MO_KEOKUK_IA: {
    label: "Quincy, IL-Hannibal, MO-Keokuk, IA",
    value: "quincy, il-hannibal, mo-keokuk, ia"
  },
  RALEIGH_DURHAM_FAYETTEVILLE_NC: {
    label: "Raleigh-Durham (Fayetteville), NC",
    value: "raleigh-durham (fayetteville), nc"
  },
  RAPID_CITY_SD: {
    label: "Rapid City, SD",
    value: "rapid city, sd"
  },
  RENO_NV: {
    label: "Reno, NV",
    value: "reno, nv"
  },
  RICHMOND_PETERSBURG_VA: {
    label: "Richmond-Petersburg, VA",
    value: "richmond-petersburg, va"
  },
  ROANOKE_LYNCHBURG_VA: {
    label: "Roanoke-Lynchburg, VA",
    value: "roanoke-lynchburg, va"
  },
  ROCHESTER_NY: {
    label: "Rochester, NY",
    value: "rochester, ny"
  },
  ROCHESTER_MN_MASON_CITY_IA_AUSTIN_MN: {
    label: "Rochester, MN-Mason City, IA-Austin, MN",
    value: "rochester, mn-mason city, ia-austin, mn"
  },
  ROCKFORD_IL: {
    label: "Rockford, IL",
    value: "rockford, il"
  },
  SACRAMENTO_STOCKTON_MODESTO_CA: {
    label: "Sacramento-Stockton-Modesto, CA",
    value: "sacramento-stockton-modesto, ca"
  },
  SALISBURY_MD: {
    label: "Salisbury, MD",
    value: "salisbury, md"
  },
  SALT_LAKE_CITY_UT: {
    label: "Salt Lake City, UT",
    value: "salt lake city, ut"
  },
  SAN_ANGELO_TX: {
    label: "San Angelo, TX",
    value: "san angelo, tx"
  },
  SAN_ANTONIO_TX: {
    label: "San Antonio, TX",
    value: "san antonio, tx"
  },
  SAN_DIEGO_CA: {
    label: "San Diego, CA",
    value: "san diego, ca"
  },
  SAN_FRANCISCO_OAKLAND_SAN_JOSE_CA: {
    label: "San Francisco-Oakland-San Jose, CA",
    value: "san francisco-oakland-san jose, ca"
  },
  SANTA_BARBARA_SANTA_MARIA_SAN_LUIS_OBISPO_CA: {
    label: "Santa Barbara-Santa Maria-San Luis Obispo, CA",
    value: "santa barbara-santa maria-san luis obispo, ca"
  },
  SAVANNAH_GA: {
    label: "Savannah, GA",
    value: "savannah, ga"
  },
  SEATTLE_TACOMA_WA: {
    label: "Seattle-Tacoma, WA",
    value: "seattle-tacoma, wa"
  },
  SHERMAN_TX_ADA_OK: {
    label: "Sherman, TX-Ada, OK",
    value: "sherman, tx-ada, ok"
  },
  SHREVEPORT_LA: {
    label: "Shreveport, LA",
    value: "shreveport, la"
  },
  SIOUX_CITY_IA: {
    label: "Sioux City, IA",
    value: "sioux city, ia"
  },
  SIOUX_FALLS_MITCHELL_SD: {
    label: "Sioux Falls (Mitchell), SD",
    value: "sioux falls(mitchell), sd"
  },
  SOUTH_BEND_ELKHART_IN: {
    label: "South Bend-Elkhart, IN",
    value: "south bend-elkhart, in"
  },
  SPOKANE_WA: {
    label: "Spokane, WA",
    value: "spokane, wa"
  },
  SPRINGFIELD_MO: {
    label: "Springfield, MO",
    value: "springfield, mo"
  },
  SPRINGFIELD_HOLYOKE_MA: {
    label: "Springfield-Holyoke, MA",
    value: "springfield-holyoke, ma"
  },
  ST_JOSEPH_MO: {
    label: "St. Joseph, MO",
    value: "st. joseph, mo"
  },
  ST_LOUIS_MO: {
    label: "St. Louis, MO",
    value: "st. louis, mo"
  },
  SYRACUSE_NY: {
    label: "Syracuse, NY",
    value: "syracuse, ny"
  },
  TALLAHASSEE_FL_THOMASVILLE_GA: {
    label: "Tallahassee, FL-Thomasville, GA",
    value: "tallahassee, fl-thomasville, ga"
  },
  TAMPA_ST_PETERSBURG_SARASOTA_FL: {
    label: "Tampa-St. Petersburg (Sarasota), FL",
    value: "tampa-st petersburg (sarasota), fl"
  },
  TERRE_HAUTE_IN: {
    label: "Terre Haute, IN",
    value: "terre haute, in"
  },
  TOLEDO_OH: {
    label: "Toledo, OH",
    value: "toledo, oh"
  },
  TOPEKA_KS: {
    label: "Topeka, KS",
    value: "topeka, ks"
  },
  TRAVERSE_CITY_CADILLAC_MI: {
    label: "Traverse City-Cadillac, MI",
    value: "traverse city-cadillac, mi"
  },
  TRI_CITIES_TN_VA: {
    label: "Tri-Cities, TN-VA",
    value: "tri-cities, tn-va"
  },
  TUCSON_SIERRA_VISTA_AZ: {
    label: "Tucson (Sierra Vista), AZ",
    value: "tucson (sierra vista), az"
  },
  TULSA_OK: {
    label: "Tulsa, OK",
    value: "tulsa, ok"
  },
  TWIN_FALLS_ID: {
    label: "Twin Falls, ID",
    value: "twin falls, id"
  },
  TYLER_LONGVIEWLUFKIN_NACOGDOCHES_TX: {
    label: "Tyler-Longview(Lufkin & Nacogdoches), TX",
    value: "tyler-longview(lufkin & nacogdoches), tx"
  },
  UTICA_NY: {
    label: "Utica, NY",
    value: "utica, ny"
  },
  VICTORIA_TX: {
    label: "Victoria, TX",
    value: "victoria, tx"
  },
  WACO_TEMPLE_BRYAN_TX: {
    label: "Waco-Temple-Bryan, TX",
    value: "waco-temple-bryan, tx"
  },
  WASHINGTON_DC_HAGERSTOWN_MD: {
    label: "Washington, DC (Hagerstown, MD)",
    value: "washington, dc (hagerstown, md)"
  },
  WATERTOWN_NY: {
    label: "Watertown, NY",
    value: "watertown, ny"
  },
  WAUSAU_RHINELANDER_WI: {
    label: "Wausau-Rhinelander, WI",
    value: "wausau-rhinelander, wi"
  },
  WEST_PALM_BEACH_FT_PIERCE_FL: {
    label: "West Palm Beach-Ft. Pierce, FL",
    value: "west palm beach-ft. pierce, fl"
  },
  WHEELING_WV_STEUBENVILLE_OH: {
    label: "Wheeling, WV-Steubenville, OH",
    value: "wheeling, wv-steubenville, oh"
  },
  WICHITA_FALLS_TX_LAWTON_OK: {
    label: "Wichita Falls, TX-Lawton, OK",
    value: "wichita falls, tx & lawton, ok"
  },
  WICHITA_HUTCHINSON_KS_PLUS: {
    label: "Wichita-Hutchinson, KS Plus",
    value: "wichita-hutchinson, ks"
  },
  WILKES_BARRE_SCRANTON_PA: {
    label: "Wilkes Barre-Scranton, PA",
    value: "wilkes barre-scranton, pa"
  },
  WILMINGTON_NC: {
    label: "Wilmington, NC",
    value: "wilmington, nc"
  },
  YAKIMA_PASCO_RICHLAND_KENNEWICK_WA: {
    label: "Yakima-Pasco-Richland-Kennewick, WA",
    value: "yakima-pasco-richland-kennewick, wa"
  },
  YOUNGSTOWN_OH: {
    label: "Youngstown, OH",
    value: "youngstown, oh"
  },
  YUMA_AZ_EL_CENTRO_CA: {
    label: "Yuma, AZ-El Centro, CA",
    value: "yuma, az-el centro, ca"
  },
  ZANESVILLE_OH: {
    label: "Zanesville, OH",
    value: "zanesville, oh"
  }
};


export interface Category {
  label: string;
  value: string;
  description?: string;
}


export interface OTT {
  NETFLIX: Category;
  DISNEY_PLUS: Category;
  HBO_MAX: Category;
  HULU: Category;
  PRIME_VIDEO: Category;
  APPLE_TV_PLUS: Category;
  PEACOCK: Category;
  PARAMOUNT_PLUS: Category;
}

export interface IntentToWatch {
  WHERE_TO_WATCH: Category;
  CABLE: Category;
  PHYSICAL: Category;
  THEATRICAL: Category;
  OTT: OTT;
  PIRACY: Category;
  LATEST_SEASON: Category;
}

export interface IntentToPlayDigital {
  STEAM: Category;
  EPIC_GAMES: Category;
  GOG: Category;
  PLAYSTATION: Category;
  XBOX: Category;
}

export interface IntentToPlayPhysical {
  CONSOLE: Category;
  PC: Category;
}

export interface IntentToPlay {
  WHERE_TO_PLAY: Category;
  DIGITAL: IntentToPlayDigital;
  PHYSICAL: IntentToPlayPhysical;
}

export interface LearnAbout {
  GENERAL: Category;
  NEWS: Category;
  SOCIAL_MEDIA: Category;
  AWARDS: Category;
  TRAILERS: Category;
}

export interface Categories {
  GENERAL: {
    CATEGORY: Category;
    RELEASE_YEAR: Category;
  };
  INTENT_TO_WATCH: IntentToWatch;
  INTENT_TO_PLAY: IntentToPlay;
  LEARN_ABOUT: LearnAbout;
  REVIEWS: Category;
}

export const CATEGORIES: Categories = {
  GENERAL: {
    CATEGORY: {
      label: "Title and category",
      value: "general.category",
      description: "Searches that are based on titles and their category. For example, 'the godfather movie' or 'minecraft game'.",
    },
    RELEASE_YEAR: {
      label: "Title and release year",
      value: "general.release_year",
      description: "Searches that are based on titles and their categories and release year. For example, 'the godfather movie 1972' or 'minecraft game 2011'.",
    },
  },
  INTENT_TO_WATCH: {
    WHERE_TO_WATCH: {
      label: "Intent to watch - Where to watch",
      value: "intent_to_watch.where_to_watch",
      description: "Searches that measure the intent to watch a movie or TV show",
    },
    CABLE: {
      label: "Intent to watch - Cable",
      value: "intent_to_watch.cable",
      description: "Searches that measure the intent to watch a movie or TV show on cable",
    },
    PHYSICAL: {
      label: "Intent to watch - Physical",
      value: "intent_to_watch.physical",
      description: "Searches that measure the intent to watch a movie or TV show on physical media",
    },
    THEATRICAL: {
      label: "Intent to watch - Theatrical",
      value: "intent_to_watch.theatrical",
      description: "Searches that measure the intent to watch a movie in theaters",
    },
    PIRACY: {
      label: "Intent to watch - Piracy",
      value: "intent_to_watch.piracy",
      description: "Searches that measure the intent to pirate a movie or TV show",
    },
    LATEST_SEASON: {
      label: "Intent to watch - Latest season",
      value: "intent_to_watch.latest_season",
      description: "Searches that measure the intent to watch the latest season of a TV show",
    },
    OTT: {
      NETFLIX: {
        label: "Intent to watch - Netflix",
        value: "intent_to_watch.ott.netflix",
        description: "Searches that measure the intent to watch a movie or TV show on Netflix",
      },
      DISNEY_PLUS: {
        label: "Intent to watch - Disney+",
        value: "intent_to_watch.ott.disneyplus",
        description: "Searches that measure the intent to watch a movie or TV show on Disney+",
      },
      HBO_MAX: {
        label: "Intent to watch - HBO Max",
        value: "intent_to_watch.ott.hbomax",
        description: "Searches that measure the intent to watch a movie or TV show on HBO Max",
      },
      HULU: {
        label: "Intent to watch - Hulu",
        value: "intent_to_watch.ott.hulu",
        description: "Searches that measure the intent to watch a movie or TV show on Hulu",
      },
      PRIME_VIDEO: {
        label: "Intent to watch - Amazon Prime Video",
        value: "intent_to_watch.ott.amazonprimevideo",
        description: "Searches that measure the intent to watch a movie or TV show on Amazon Prime Video",
      },
      APPLE_TV_PLUS: {
        label: "Intent to watch - Apple TV+",
        value: "intent_to_watch.ott.appletvplus",
        description: "Searches that measure the intent to watch a movie or TV show on Apple TV+",
      },
      PEACOCK: {
        label: "Intent to watch - Peacock",
        value: "intent_to_watch.ott.peacock",
        description: "Searches that measure the intent to watch a movie or TV show on Peacock",
      },
      PARAMOUNT_PLUS: {
        label: "Intent to watch - Paramount Plus",
        value: "intent_to_watch.ott.paramountplus",
        description: "Searches that measure the intent to watch a movie or TV show on Paramount Plus",
      },
    },
  },
  INTENT_TO_PLAY: {
    WHERE_TO_PLAY: {
      label: "Intent to play - Where to play",
      value: "intent_to_play.where_to_play",
      description: "Searches that measure the intent to play a video game",
    },
    DIGITAL: {
      STEAM: {
        label: "Intent to play - Steam",
        value: "intent_to_play.digital.steam",
        description: "Searches that measure the intent to play a video game on Steam",
      },
      EPIC_GAMES: {
        label: "Intent to play - Epic Games",
        value: "intent_to_play.digital.epicgames",
        description: "Searches that measure the intent to play a video game on Epic Games",
      },
      GOG: {
        label: "Intent to play - GOG",
        value: "intent_to_play.digital.gog",
        description: "Searches that measure the intent to play a video game on GOG",
      },
      PLAYSTATION: {
        label: "Intent to play - PlayStation",
        value: "intent_to_play.digital.playstation",
        description: "Searches that measure the intent to play a video game on PlayStation",
      },
      XBOX: {
        label: "Intent to play - Xbox",
        value: "intent_to_play.digital.xbox",
        description: "Searches that measure the intent to play a video game on Xbox",
      },
    },
    PHYSICAL: {
      CONSOLE: {
        label: "Intent to play - Console",
        value: "intent_to_play.physical.console",
        description: "Searches that measure the intent to play a video game on console",
      },
      PC: {
        label: "Intent to play - PC",
        value: "intent_to_play.physical.pc",
        description: "Searches that measure the intent to play a video game on PC",
      },
    },
  },
  LEARN_ABOUT: {
    GENERAL: {
      label: "Learn about - General",
      value: "learn_about.general",
      description: "Searches that are based on people wanting to learn more about a movie, TV show, or video game",
    },
    NEWS: {
      label: "Learn about - News",
      value: "learn_about.news",
      description: "Searches that are based on people wanting to get the latest news on a movie, TV show, or video game",
    },
    SOCIAL_MEDIA: {
      label: "Learn about - Social media",
      value: "learn_about.social",
      description: "Searches that are based on people wanting to get the latest social media updates on a movie, TV show, or video game",
    },
    AWARDS: {
      label: "Learn about - Awards",
      value: "learn_about.awards",
      description: "Searches that are based on people wanting to learn about the awards a movie, TV show, or video game has won",
    },
    TRAILERS: {
      label: "Learn about - Trailers",
      value: "learn_about.trailers",
      description: "Searches that are based on people wanting to check out trailers for a movie, TV show, or video game",
    },
  },
  REVIEWS: {
    label: "Learn about - Reviews",
    value: "reviews",
    description: "Searches that are based on people wanting to read reviews of a movie, TV show, or video game",
  },
};

export const ALL_CATEGORY: Category = {
  label: "All search categories",
  value: "all",
  description: "The total search volume across all search categories",
};

export const DEFAULT_GOOGLE_FITLERS: FilterObject = { 
  ...DEFAULT_TRENDING_FILTERS, 
  filters: [
    ...DEFAULT_TRENDING_FILTERS.filters.filter(f => f.field !== "ip_list"),
    {
      field: "category",
      label: "Search category",
      value: ["general.category"],
      filterType: FilterType.Text,
      filterOperation: FilterOperation.Equals,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        setOptions: [ALL_CATEGORY, ...getAllCategories(CATEGORIES)],
      },
    },
  ],
};

export const googleApiParams = {
  type: TYPES.PERSONAL,
  service: SERVICES.PLATFORMS,
  module,
  tab: TABS.TRENDS,
};

const googleCategories = [
  BASE_CATEGORIES.MOVIES,
  BASE_CATEGORIES.SERIES,
  BASE_CATEGORIES.GAMING,
];

export const googleCategoryOptions = categoryOptions.filter((option) =>
  googleCategories.includes(option.value),
);

export const defaultCategory = BASE_CATEGORIES.MOVIES;
