import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import React, { useContext, createContext } from "react";
import { ColumnDefinition, SortBy } from "../../../constants";

type CreateReportContextProps = {
  reportName: string;
  setReportName: (name: string) => void;
  selectedListOption: OptionDefinition;
  setSelectedListOption: (list: OptionDefinition) => void;
  shareWithUsers: Array<any>;
  setShareWithUsers: (users: Array<any>) => void;
  selectedTitleList: any;
  setSelectedTitleList: (list: any) => void;
  columnDefinitions: Array<ColumnDefinition>;
  setColumnDefinitions: (columns: Array<ColumnDefinition>) => void;
  sortBy: Array<SortBy>;
  setSortBy: (sort: Array<SortBy>) => void;
  limit: number;
  setLimit: (limit: number) => void;
  verifyErrors: Array<string>;
  selectedTitlesLength: number;
  setSelectedTitlesLength: (length: number) => void;
  setActiveStepIndex: (index: number) => void;
  lastDateRangeValue: any;
  setLastDateRangeValue: (value: any) => void;
  lastDateValue: any;
  setLastDateValue: (value: any) => void;
  previousReportId: string;
  setPreviousReportId: (id: string) => void;
  version: number;
  setVersion: (version: number) => void;
};

export const CreateReportContext = createContext<CreateReportContextProps>(null);

export const useCreateReportContext = () => {
  return useContext(CreateReportContext);
};