import { useCollection } from "@cloudscape-design/collection-hooks";
import { Button, Header, Link, Pagination, Popover, StatusIndicator, TextFilter } from "@cloudscape-design/components";
import moment from "moment";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";

interface UseTableConfigProps {
  jobTableItems?: Array<any>;
  pageSize?: number;
}

export const useTableConfig = ({
  jobTableItems=[],
  pageSize=10,
}: UseTableConfigProps) => {

  const navigate = useNavigate();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(jobTableItems, {
    filtering: {},
    pagination: { pageSize },
    sorting: {},
    selection: {},
  });

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const columnDefinitions = useMemo(() => {
    return [
      {
        id: "name",
        header: "Name",
        cell: (item) => (
          <Header
            description=""
          >
            <div className="flex items-center space-x-2">
              <Button 
                href={`/tools/report-builder/report/${item.id}`} 
                variant="inline-link" 
                disabled={item.status !== "FINISHED"}
                onFollow={onFollowHandler}
              >
                {item.name}
              </Button>
              {item.shared_with.length > 0 && (
                <Popover
                  dismissButton={false}
                  position="right"
                  triggerType="custom"
                  size="large"
                  content={
                    <div className="space-y-2">
                      <p>
                        Shared with: <b>{item.shared_with.join(", ")}</b>
                      </p>
                    </div>
                  }
                >
                  <Button iconName="group-active" variant="inline-icon" />
                </Popover>
              )}
              {item.version > 1 && (
                <Popover
                  dismissButton={false}
                  position="right"
                  triggerType="text"
                  size="large"
                  content={
                    <div>
                      <div className="text-lg font-bold mb-2">Previous versions</div>
                      <ul className="list-disc ml-4">
                        {item.previous_versions.sort((a, b) => b.version - a.version).map((version) => (
                          <li>
                            <Link href={`/tools/report-builder/report/${version.id}`} external>{version.name}</Link>
                            <div className="flex gap-1 items-center text-xs dark:text-gray-400 text-gray-600">
                              <span>Version {version.version}</span>
                              <span>&bull;</span>
                              <span>{moment(version.created_at * 1000).calendar()}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <div className="text-xs font-normal dark:text-gray-400 text-gray-600">Version {item.version}</div>
                </Popover>
              )}
            </div>
          </Header>
        ),
        isRowHeader: true,
        minWidth: 300,
      },
      {
        id: "created_at",
        header: "Created at",
        cell: item => new Date(item.created_at * 1000).toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }),
      },
      {
        id: "created_by",
        header: "Created by",
        cell: item => item.created_by,
      },
      {
        id: "duration",
        header: "Duration",
        cell: (item) => {
          let updatedTime = item.updated_at;
          if (item.status === "RUNNING") {
            updatedTime = Math.floor(Date.now() / 1000);
          }
          const duration = updatedTime - item.created_at;
          const hours = Math.floor(duration / 3600);
          const minutes = Math.floor((duration % 3600) / 60);
          const seconds = duration % 60;
          return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : `${minutes}m ${seconds}s`;
        },
      },
      {
        id: "status",
        header: "Status",
        cell: item => {
          switch (item.status) {
            case "RUNNING":
              return (
                <StatusIndicator type="pending">
                  Running
                </StatusIndicator>
              );
            case "FINISHED":
              return (
                <StatusIndicator>
                  Finished
                </StatusIndicator>
              );
            case "FAILED":
              return (
                <StatusIndicator type="error">
                  Failed
                </StatusIndicator>
              );
          }
        },
      },
    ];
  }, [Math.floor(Date.now() / 1000)]);

  return {
    items,
    collectionProps,
    filter: (
      <TextFilter
        {...filterProps}
        filteringPlaceholder="Find reports"
        filteringAriaLabel="Filter reports"
      />
    ),
    pagination: (
      <Pagination {...paginationProps} />
    ),
    columnDefinitions,
  };
};