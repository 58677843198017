import React, { useEffect, useState } from "react";

const useStickyStatus = (elementRef) => {
  const [isAtTop, setIsAtTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!elementRef.current) return;
      const elementTop = elementRef.current?.getBoundingClientRect()?.top;
      setIsAtTop(elementTop <= 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return isAtTop;
};

export default useStickyStatus;