import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTrendingRankingDataV3 } from "../request";
import { isSome } from "../../../../../utils/sugarUtils";

export type UseGetTrackedRankingProps = {
  startTs: number;
  endTs: number;
  ipList: string;
  offset?: number;
  limit?: number;
  sort?: string;
  sortOrder?: "asc" | "desc";
  weights?: any;
  filters?: any;
};

export const useGetTrackedRankingData = (params: UseGetTrackedRankingProps) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TRACKED_RANKING_DATA, { ...params }],
    () => getTrendingRankingDataV3(params as any),
    {
      enabled:
        isSome(params.startTs) &&
        isSome(params.endTs),
    },
  );

  return { data, isLoading, error: error as any };
};
