import moment from "moment";

export const isDataAvailable = (data) =>
  (
    data?.errors == null ||
    data?.errors?.length === 0 ||
    data?.errors?.filter((x) => x.code === "NO_DATA_AVAILABLE")?.length === 0
  ) && data?.data?.length > 0;

export const calculateDateRange = (releaseDate) => {
  const releaseDateMoment = moment(releaseDate);
  const rangeProps = {
    type: "relative",
    amount: 3,
    unit: "month",
    startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  while (rangeProps.amount > 1) {
    if (releaseDateMoment.isAfter(moment().subtract(rangeProps.amount - 1, rangeProps.unit as moment.DurationInputArg2))) {
      rangeProps.amount -= 1;
      rangeProps.startDate = moment().subtract(rangeProps.amount, rangeProps.unit as moment.DurationInputArg2).format("YYYY-MM-DD");
    } else {
      break;
    }
  }

  return rangeProps;
};
