import axiosInstance from "../../../../utils/axios";

export const getTrendingRankingDataV3 = ({
  startTs,
  endTs,
  offset=null,
  limit=null,
  sort=null,
  sortOrder=null,
  weights=null,
  filters=null,
  download=null,
}) => {

  const params = {} as any;
  if (startTs) {
    params.start_ts = startTs;
  }
  if (endTs) {
    params.end_ts = endTs;
  }
  if (offset) {
    params.offset = offset;
  }
  if (limit) {
    params.limit = limit;
  }
  if (sort) {
    params.sort = sort;
  }
  if (sortOrder) {
    params.sort_order = sortOrder;
  }
  if (download) {
    params.download = download;
  }

  const body = {} as any;
  if (weights) {
    body.weights = weights;
  }
  if (filters) {
    body.filter = filters;
  }


  return axiosInstance.request({
    url: `/ranking/raw/v3`,
    method: "POST",
    params,
    data: body,
    headers: {
      "Content-Type": "text/plain",
    },
  });
};


