import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";

interface DeleteTrailerConfirmationModalProps {
  visible: boolean;
  isLoading: boolean;
  onDelete: () => void;
  onDismiss: () => void;
}

export const DeleteTrailerConfirmationModal: FC<
  DeleteTrailerConfirmationModalProps
> = ({ visible, isLoading, onDelete, onDismiss }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss} loading={isLoading}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onDelete} loading={isLoading}>
              Remove
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Remove Trailers"
    >
      Are you sure you want to remove the selected trailers from this title?
    </Modal>
  );
};
