import React from "react";
import { FC } from "react";
import { Icon, Link, Popover, ProgressBar, Box, TextContent, SpaceBetween } from "@cloudscape-design/components";


interface ScoreProps {
  title: string,
  platform: string,
  rating: {
    average_rating: number,
    ratings?: number,
    ratings_count?: number,
  };
  link: string
}

const getReviewsPercentages = (platform, count, item)=>{
  //Amazon returns percentages while the others return counts
  if(platform ==="amazonbooks"){
    return item * 100
  }
  return item/count * 100
}

const BookScore: FC<ScoreProps> = ({ title, platform, rating, link }) => {
  const ratings = ["5_star", "4_star", "3_star","2_star", "1_star"];
  const { average_rating } = rating
  const count = rating?.ratings || rating?.ratings_count
  return (
    <div className="flex items-center space-x-2">
      <img src={`/images/${platform}_logo.png`} alt="Stars" className="h-12 w-12 rounded-lg" />
      <div className="text-black dark:text-slate-100">
        <div className="text-lg dark:text-slate-400 font-bold leading-[1.25rem]">
          <span className="pr-1 dark:text-slate-50">{average_rating}</span>/<span className="text-base px-[2px]">5</span>
        </div>
        {link ? (
          <Link 
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="text-xs font-bold">{title}{" "}<Icon name="external" size="inherit" /></div>
          </Link>
        ) : null}
        <div className="text-xs italic dark:text-slate-400">
          <Popover
            fixedWidth
            position="right"
            triggerType="text"
            content={
              <SpaceBetween direction="vertical" size="s">
                <TextContent>
                  {Array.from({ length: 5 }).map((item, index)=> {
                    const starIndex = index + 1
                    const name = starIndex <= average_rating ? "star-filled" : starIndex - 0.5 <= average_rating ? "star-half" : "star-filled"
                    const variant = starIndex <= average_rating || starIndex - 0.5 <= average_rating ? "link": "inverted"
                    return <Icon name={name} variant={variant}/>
                  })}
                  <span className="px-2 dark:text-slate-50 font-bold">{average_rating} out of 5 stars</span>
                  <p>
                    <small>
                    {count.toLocaleString()} ratings
                    </small>
                  </p>
                </TextContent>
                <div>
                  {ratings.map((item, index) => (
                    <div className="flex justify-between gap-6">
                      <span>{ratings.length - index} star</span>
                      <div className="grow">
                        <ProgressBar value={getReviewsPercentages(platform, count ,rating[item])} variant="standalone"/>
                      </div>
                    </div>
                  ))}
                </div>
              </SpaceBetween>
            }
          >
            <div className="text-xs italic dark:text-slate-400">
              {rating?.ratings?.toLocaleString() || rating?.ratings_count?.toLocaleString()} ratings
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default BookScore;
