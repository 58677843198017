import { Grid, Spinner } from "@cloudscape-design/components";
import { Mode } from "@cloudscape-design/global-styles";
import React, { FC, useMemo } from "react";
import { useThemeContext } from "../../../../../app/ThemeProvider";
import { isDataAvailable } from "../../../utils";
import { ImdbTrends } from "./ImdbTrends/ImdbTrends";
import { SteamTrends } from "./SteamTrends/SteamTrends";
import { TwitchTrends } from "./TwitchTrends/TwitchTrends";
import { WikipediaTrends } from "./WikipediaTrends/WikipediaTrends";
import { YoutubeTrends } from "./YoutubeTrends/YoutubeTrends";
import { getFormattedEventLabel } from "../../../../../hooks/TimelineEvents/useGetTimelineEvents";
import { RottenTomatoesTrends } from "./RottenTomatoesTrends/RottenTomatoesTrends";
import { useGetPlatformData } from "../../game/api/hooks/useGetPlatformData";
import { Vertical } from "../../../../../types/verticalTypes";
import { RedditTrends } from "./RedditTrends/RedditTrends";
import { PiracyTrends } from "./PiracyTrends/PiracyTrends";
import { useGetTrackedRedditData } from "../../../api/hooks/useGetTrackedRedditData";
import { useGetPiracyTrendsData } from "../../../api/hooks/useGetPiracyTrendsData";
import { GoogleTitleInsights } from "../GoogleTitleInsights";

interface RankTrendsProps {
  dateRange: any;
  itemData: any;
  configType: "basic" | "advanced";
}

export const RankTrends: FC<RankTrendsProps> = ({
  dateRange,
  itemData,
  configType,
}) => {
  const { mode } = useThemeContext();

  const { data: youtubeTrendsData, isLoading: youtubeIsLoading } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "youtube",
    dateRange,
    includeZScore: true,
    enabled: [Vertical.Movies, Vertical.Series, Vertical.Games].includes(itemData?.data?.vertical),
  });
  const { data: imdbTrendsData, isLoading: imdbIsLoading } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "imdb",
    dateRange,
    includeZScore: true,
    enabled: [Vertical.Movies, Vertical.Series].includes(itemData?.data?.vertical),
  });
  const { data: wikipediaTrendsData, isLoading: wikipediaIsLoading } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "wiki",
    dateRange,
    includeZScore: true,
    enabled: [Vertical.Movies, Vertical.Series, Vertical.Games].includes(itemData?.data?.vertical),
  });
  const { data: rtTrendsData, isLoading: rtIsLoading } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "rt",
    dateRange,
    includeZScore: true,
    enabled: [Vertical.Movies, Vertical.Series].includes(itemData?.data?.vertical),
  });
  const { data: twitchTrendsData, isLoading: twitchIsLoading } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "twitch",
    dateRange,
    includeZScore: true,
    enabled: [Vertical.Games].includes(itemData?.data?.vertical),
  });
  const { data: steamTrendsData, isLoading: steamIsLoading } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "steam",
    dateRange,
    includeZScore: true,
    enabled: [Vertical.Games].includes(itemData?.data?.vertical),
  });
  const { data: redditTrendsData, isLoading: redditIsLoading } = useGetTrackedRedditData({
    ipId: itemData?.data?.ip_id,
    dateRange,
    enabled: [Vertical.Movies, Vertical.Series, Vertical.Games].includes(itemData?.data?.vertical),
  });
  const { data: piracyTrendsData, isLoading: piracyIsLoading } = useGetPiracyTrendsData({
    ipId: itemData?.data?.ip_id,
    dateRange,
    enabled: [Vertical.Movies, Vertical.Series].includes(itemData?.data?.vertical),
  });

  const crosslines = useMemo(
    () =>
      itemData?.data?.timeline_events?.map((event) => ({
        type: "line",
        enabled: true,
        value: event.timestamp * 1000,
        trueValue: event.timestamp,
        lineDash: [5, 5],
        strokeOpacity: 0.7,
        stroke: mode === Mode.Dark ? "lightgray" : "gray",
        text: getFormattedEventLabel(event) ?? "",
        event_type: event.eventType ?? "",
        label: {
          enabled: false,
          text: getFormattedEventLabel(event),
        },
        created_by: event.createdBy ?? "",
      })) ?? [],
    [itemData, mode],
  );

  const isLoading = youtubeIsLoading || imdbIsLoading || wikipediaIsLoading || rtIsLoading || twitchIsLoading || steamIsLoading || redditIsLoading || piracyIsLoading;

  return (
    isLoading ? (
      <div className="h-[40vh] w-full flex items-center justify-center">
        <Spinner size="large" />
      </div>
    ) : (
      <div className="flex flex-col gap-4">
        <Grid
          gridDefinition={[
            { colspan: { default: 12, m: 6 } },
            { colspan: { default: 12, m: 6 } },
            { colspan: { default: 12, m: 6 } },
            { colspan: { default: 12, m: 6 } },
            { colspan: { default: 12, m: 6 } },
            { colspan: { default: 12, m: 6 } },
            { colspan: { default: 12, m: 6 } },
            { colspan: { default: 12, m: 6 } },
          ]}
        >
          {isDataAvailable(wikipediaTrendsData) && (
            <WikipediaTrends
              itemName={itemData?.data?.name}
              rankInfo={itemData?.data?.ip_ranking?.wiki}
              crosslines={crosslines}
              data={wikipediaTrendsData}
              dateRange={dateRange}
              configType={configType}
            />
          )}
          {isDataAvailable(youtubeTrendsData) && (
            <YoutubeTrends
              itemName={itemData?.data?.name}
              rankInfo={itemData?.data?.ip_ranking?.youtube}
              crosslines={crosslines}
              data={youtubeTrendsData}
              dateRange={dateRange}
              configType={configType}
            />
          )}
          {isDataAvailable(imdbTrendsData) && (
            <ImdbTrends
              itemName={itemData?.data?.name}
              rankInfo={itemData?.data?.ip_ranking?.imdb}
              crosslines={crosslines}
              data={imdbTrendsData}
              dateRange={dateRange}
              configType={configType}
            />
          )}
          {isDataAvailable(rtTrendsData) && (
            <RottenTomatoesTrends
              itemName={itemData?.data?.name}
              rankInfo={itemData?.data?.ip_ranking?.rt}
              crosslines={crosslines}
              data={rtTrendsData}
              dateRange={dateRange}
              configType={configType}
            />
          )}
          {isDataAvailable(steamTrendsData) && (
            <SteamTrends
              itemName={itemData?.data?.name}
              rankInfo={itemData?.data?.ip_ranking?.steam}
              crosslines={crosslines}
              data={steamTrendsData}
              dateRange={dateRange}
              configType={configType}
            />
          )}
          {isDataAvailable(twitchTrendsData) && (
            <TwitchTrends
              itemName={itemData?.data?.name}
              rankInfo={itemData?.data?.ip_ranking?.twitch}
              crosslines={crosslines}
              data={twitchTrendsData}
              dateRange={dateRange}
              configType={configType}
            />
          )}
          {configType === "advanced" && (
            <>
              {isDataAvailable(redditTrendsData) && (
                <RedditTrends 
                  data={redditTrendsData}
                />
              )}
              {isDataAvailable(piracyTrendsData) && (
                <PiracyTrends 
                  data={piracyTrendsData}
                />
              )}
            </>
          )}
        </Grid>
        {configType === "advanced" && (
          <GoogleTitleInsights
            titleData={itemData.data}
            dateRange={dateRange}
          />
        )}
      </div>
    )
  );
};
