import { Autosuggest, Box, Button, ButtonDropdown, FormField, Header, Icon, Input, Select, SpaceBetween, Table, TokenGroup } from "@cloudscape-design/components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTableConfig } from "./hooks/useTableConfig";
import { useAuthContext } from "../../../../../auth/useAuthContext";
import { FaRegEdit } from "react-icons/fa";
import { useGetJobListData } from "../../api/hooks/useGetJobListData";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../api/constants";
import { deleteReport, getReportResults, saveTitleListData, updateReport } from "../../api/request";
import { MessageBox } from "../../../../../components/MessageBox";
import { COLUMN_DEFINITIONS, listOptions, ReportDefinition } from "../../constants";
import { useGetUserData } from "../../api/hooks/useGetUserData";

interface JobListProps {
  listTabId: "personal" | "organization" | "shared";
  setWizardVisible: (visible: boolean) => void;
  setWizardDefaults?: (defaults: any) => void;
}

export const JobList: React.FC<JobListProps> = ({
  listTabId,
  setWizardVisible,
  setWizardDefaults,
}) => {

  const messageBoxRef = useRef(null);

  const { user } = useAuthContext();
  const queryClient = useQueryClient();

  const [selectedJobTableItems, setSelectedJobTableItems] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [editDetailsModalVisible, setEditDetailsModalVisible] = useState(false);
  const [renameTextValue, setRenameTextValue] = useState("");
  const [moveToListValue, setMoveToListValue] = useState(null);
  const [shareWithUsers, setShareWithUsers] = useState([]);

  const { data: jobTableItems, isLoading, error } = useGetJobListData({ list: listTabId });

  const {
    items,
    collectionProps,
    filter,
    pagination,
    columnDefinitions,
  } = useTableConfig({
    jobTableItems: jobTableItems?.data || [],
    pageSize: 10,
  });

  const refreshJobList = () => {
    queryClient.invalidateQueries(QUERY_KEYS.GET_JOB_LIST_DATA);
  };

  const exportData = (jobId, format) => {
    setIsExporting(true);

    getReportResults({
      id: jobId,
      download: format,
    }).then(data => {
      const url = data.data;
      window.location.href = url;
      setIsExporting(false);
    });
  };

  const editReportDetails = (jobId, parameters) => {
    updateReport({
      id: jobId,
      action: "edit",
      ...parameters,
    }).then(() => {
      refreshJobList();
    });
  };

  const [userAutoSuggestValue, setUserAutoSuggestValue] = useState("");
  const [userAutoSuggestLoadValue, setUserAutoSuggestLoadValue] = useState("");

  const { data: userData, isLoading: userDataLoading, error: userDataError } = useGetUserData({
    username: userAutoSuggestLoadValue,
  });

  const userAutoSuggestOptions = useMemo(() => {
    if (!userData) return [];

    return [
      {
        value: userData.user_name,
        label: userData.name,
        tags: [userData.email_address, userData.organization],
      },
    ];
  }, [userData]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refreshJobList();
    }, 30000);

    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedJobTableItems(detail.selectedItems)}
        selectedItems={selectedJobTableItems}
        columnDefinitions={columnDefinitions}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "created_at", visible: true },
          { id: "created_by", visible: listTabId == "organization" || listTabId == "shared" },
          { id: "duration", visible: true },
          { id: "status", visible: true },
          { id: "actions", visible: true },
        ]}
        loadingText="Loading reports"
        items={items}
        variant="embedded"
        selectionType="single"
        loading={isLoading}
        pagination={pagination}
        filter={filter}
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No reports found</b>
              <Button
                variant="primary"
                iconSvg={<span className="w-full h-full flex items-center justify-center scale-125"><FaRegEdit /></span>}
                onClick={() => setWizardVisible(true)}
              >
                Create report
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            variant="h2"
            description={listTabId == "personal" ? "View your reports and create new ones" : listTabId == "organization" ? "View your organization's reports and create new ones" : "View reports shared with you"}
            counter={`(${jobTableItems?.data?.length || 0})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  iconName="refresh"
                  onClick={() => {
                    refreshJobList();
                  }}
                  loading={isLoading}
                />
                <ButtonDropdown
                  items={[
                    { text: "Export report as CSV", id: "download_report_csv", disabled: selectedJobTableItems[0]?.status !== "FINISHED" },
                    { text: "Export report as XLSX", id: "download_report_xlsx", disabled: selectedJobTableItems[0]?.status !== "FINISHED" },
                    { text: "Edit", id: "edit", disabled: selectedJobTableItems[0]?.created_by !== user.username },
                    { text: "Clone", id: "clone" },
                    { text: "Delete", id: "delete" },
                  ]}
                  disabled={selectedJobTableItems.length == 0 || selectedJobTableItems[0] == null}
                  onItemClick={({ detail }) => {
                    const selectedJob = selectedJobTableItems[0];
                    switch (detail.id) {
                      case "download_report_csv":
                        exportData(selectedJob.id, "csv");
                        break;
                      case "download_report_xlsx":
                        exportData(selectedJob.id, "xlsx");
                        break;
                      case "edit":
                        setRenameTextValue(selectedJob.name);
                        setMoveToListValue(listOptions.find((option) => option.value == selectedJob.list));
                        setShareWithUsers(selectedJob.shared_with.map((user) => ({
                          value: user,
                          label: user,
                        })));
                        setEditDetailsModalVisible(true);
                        break;
                      case "clone":
                        const clonedTitleListName = `##joblist##_${selectedJob.id}`;
                        selectedJob.report_definition.previous_report_id = null;
                        selectedJob.report_definition.version = 1;
                        selectedJob.report_definition.title_list.name = clonedTitleListName;
                        selectedJob.report_definition.column_definitions = selectedJob.report_definition.column_definitions.map((col) => ({
                          ...col,
                          controlOptions: col.controlOptions ?? COLUMN_DEFINITIONS.find((c) => c.source === col.source && c.field === col.field)?.controlOptions ?? [],
                        }));
                        setWizardDefaults(selectedJob);
                        setWizardVisible(true);
                        break;
                      case "delete":
                        messageBoxRef.current.open({
                          headerText: "Delete report",
                          messageBody: (<span>Are you sure you want to delete report <b>{selectedJob.name}</b>?</span>),
                          primaryButtonText: "Delete",
                          secondaryButtonText: "Cancel",
                          onPrimaryButtonClick: () => {
                            deleteReport({
                              id: selectedJob.id,
                            }).then(() => {
                              refreshJobList();
                            });
                          },
                          onSecondaryButtonClick: () => {},
                        });
                        break;
                    }
                  }}
                  loading={isExporting}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant="primary"
                  iconSvg={<span className="w-full h-full flex items-center justify-center scale-125"><FaRegEdit /></span>}
                  onClick={() => {
                    setWizardDefaults({
                      report_definition: {
                        column_definitions: [
                          COLUMN_DEFINITIONS.find(x => x.id === "ip_id"),
                          COLUMN_DEFINITIONS.find(x => x.id === "title"),
                          COLUMN_DEFINITIONS.find(x => x.id === "vertical"),
                        ],
                      },
                    });
                    setWizardVisible(true);
                  }}
                >
                  Create report
                </Button>
              </SpaceBetween>
            }
          >
            {listTabId == "personal" ? "My reports" : listTabId == "organization" ? `Organization reports - ${user["custom:organization"]}` : "Shared with me"}
          </Header>
        }
      />
      <MessageBox ref={messageBoxRef} />
      <MessageBox
        headerText="Edit report"
        messageBody={
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input 
                value={renameTextValue} 
                placeholder="Enter a name for your report" 
                onChange={({ detail }) => setRenameTextValue(detail.value)}
              />
            </FormField>
            <FormField label="Save to list">
              <Select
                options={listOptions}
                selectedOption={moveToListValue}
                onChange={({ detail }) => setMoveToListValue(detail.selectedOption)}
              />
            </FormField>
            <FormField label={<span>Share with{" "}<i>- optional</i></span>}>
              <>
                <Autosuggest
                  onChange={({ detail }) => setUserAutoSuggestValue(detail.value)}
                  value={userAutoSuggestValue}
                  onLoadItems={({ detail }) => setUserAutoSuggestLoadValue(detail.filteringText)}
                  onSelect={({ detail }) => {
                    if (!detail.selectedOption) return;
                    const user = detail.selectedOption;
                    setShareWithUsers([...shareWithUsers, user]);
                    setUserAutoSuggestValue("");
                  }}
                  options={userAutoSuggestOptions}
                  placeholder="Add users by email or username"
                  empty="No users found"
                  loadingText="Loading users..."
                  statusType={userDataLoading ? "loading" : "finished"}
                  enteredTextLabel={(value) => `Use: ${value}`}
                />
                <TokenGroup
                  items={shareWithUsers.map(u => ({ ...u, disabled: u.value === user.username }))}
                  onDismiss={({ detail: { itemIndex } }) => {
                    setShareWithUsers([
                      ...shareWithUsers.slice(0, itemIndex),
                      ...shareWithUsers.slice(itemIndex + 1)
                    ]);
                  }}
                />
              </>
            </FormField>
            <Button
              variant="inline-link"
              onClick={() => {
                const selectedJob = selectedJobTableItems[0];
                const editTitleListName = `##joblist##_${selectedJob.id}`;
                selectedJob.name = renameTextValue;
                selectedJob.list = moveToListValue.value;
                selectedJob.shared_with = shareWithUsers.map((user) => user.value);
                selectedJob.report_definition.name = renameTextValue;
                selectedJob.report_definition.save_list = moveToListValue.value;
                selectedJob.report_definition.shared_with = shareWithUsers.map((user) => user.value);
                selectedJob.report_definition.previous_report_id = selectedJob.id;
                selectedJob.report_definition.version = (selectedJob.report_definition.version ?? 1) + 1;
                selectedJob.report_definition.title_list.name = editTitleListName;
                selectedJob.report_definition.column_definitions = selectedJob.report_definition.column_definitions.map((col) => ({
                  ...col,
                  controlOptions: col.controlOptions ?? COLUMN_DEFINITIONS.find((c) => c.source === col.source && c.field === col.field)?.controlOptions ?? [],
                }));
                setWizardDefaults(selectedJob);
                setWizardVisible(true);
                setEditDetailsModalVisible(false);
              }}
            >
              Edit titles and columns{" "}<Icon name="angle-right" />
            </Button>
          </SpaceBetween>
        }
        primaryButtonText="Submit"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={() => {
          editReportDetails(selectedJobTableItems[0].id, {
            name: renameTextValue,
            list: moveToListValue.value,
            shared_with: shareWithUsers.map((user) => user.value).join(","),
          });
          setEditDetailsModalVisible(false);
        }}
        onSecondaryButtonClick={() => {
          setEditDetailsModalVisible(false);
        }}
        modalIsOpen={editDetailsModalVisible}
        setModalIsOpen={setEditDetailsModalVisible}
      />
    </>
  );
};