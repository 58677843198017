import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getLatestTimes } from "../request";


export const useGetLatestTimes = () => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_LATEST_TIMES, {  }],
    () => getLatestTimes(),
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};