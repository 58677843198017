import React, { useState } from "react";
import { TimeseriesChart } from "../../../platforms/google/components/TimeseriesChart/TimeseriesChart";
import { CountryMap } from "../../../platforms/google/components/CountryMap/CountryMap";
import { getAllCategories } from "../../../platforms/google/utils";
import { CATEGORIES, COUNTRIES, Country, DMA_REGIONS } from "../../../platforms/google/constants";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";
import { Container } from "@cloudscape-design/components";

const mainCountries: Array<Country> = [
  COUNTRIES.AUSTRALIA,
  COUNTRIES.BRAZIL,
  COUNTRIES.CANADA,
  COUNTRIES.GERMANY,
  COUNTRIES.SPAIN,
  COUNTRIES.FRANCE,
  COUNTRIES.UNITED_KINGDOM,
  COUNTRIES.IRELAND,
  COUNTRIES.ITALY,
  COUNTRIES.JAPAN,
  COUNTRIES.SOUTH_KOREA,
  COUNTRIES.MEXICO,
  COUNTRIES.THAILAND,
  COUNTRIES.UNITED_STATES_OF_AMERICA,
  COUNTRIES.INDIA,
];

export const GoogleTitleInsights = ({
  titleData,
  dateRange,
}) => {

  return (
    <Container>
      <div className="flex flex-col gap-4 w-full">
        <TimeseriesChart
          selectedTitle={titleData} 
          dateRange={dateRange}
          categories={getAllCategories(CATEGORIES)}
          countries={mainCountries}
        />
        <CountryMap
          selectedTitle={titleData}
          categories={getAllCategories(CATEGORIES)}
          countries={Object.values(COUNTRIES)}
          dmaRegions={Object.values(DMA_REGIONS)}
          dateRange={dateRange}
        />
      </div>
    </Container>
  );
};