import { useCallback, useEffect } from "react";

interface UseFetchServerSideGridDataProps {
  filter: any;
  gridRef: any;
  ipList: string;
  fetchMethod: any;
  disabled: boolean;
  startTs: number;
  endTs: number;
  weights: any;
}

export const useFetchServerSideGridData = ({
  filter,
  gridRef,
  ipList,
  fetchMethod,
  disabled,
  startTs,
  endTs,
  weights,
}: UseFetchServerSideGridDataProps) => {
  const createServer = useCallback(() => {
    return {
      getData: async (request) => {
        const filters = filter;
        const sortModel = request?.sortModel ?? [];
        const sort = sortModel[0]?.colId;
        const sortOrder = sortModel[0]?.sort;
        const offset = request.startRow;
        const limit = request.endRow - request.startRow;

        const requestedRows = await fetchMethod({
          startTs,
          endTs,
          ipList,
          sort,
          sortOrder,
          limit,
          offset,
          filters,
          weights,
        });

        return {
          success: true,
          rows: requestedRows?.data?.results ?? [],
          total: requestedRows?.data?.total ?? 0,
        };
      },
    };
  }, [filter, ipList, startTs, endTs, weights]);

  const createServerSideDatasource = (server) => {
    return {
      getRows: async (params) => {
        const response = await server.getData(params.request);

        if (response.success) {
          params.success({
            rowData: response.rows,
            rowCount: response.total,
          });
        } else {
          params.fail();
        }
      },
    };
  };

  useEffect(() => {
    if (disabled) return;

    const server = createServer();
    const dataSource = createServerSideDatasource(server);
    gridRef?.current?.api?.setGridOption("serverSideDatasource", dataSource);
  }, [disabled, createServer, gridRef?.current]);
};
