import { IP_LIST } from "../../../config-global";
import { FilterControlType, FilterJoinOperation, FilterObject, FilterOperation, FilterType } from "../../../types/filterTypes";
import { Vertical, verticalOptionsWithAll } from "../../../types/verticalTypes";
import { WeightObject } from "../../../types/weightTypes";

export const platforms = [
  { key: "twitter", name: "X (Twitter)" },
  { key: "google", name: "Google Search" },
  { key: "wiki", name: "Wikipedia" },
  { key: "a03", name: "AO3" },
  { key: "youtube", name: "YouTube" },
  { key: "reddit", name: "Reddit" },
  { key: "fandom", name: "Fandom" },
  // { key: "instagram", name: "Instagram" },
  { key: "tiktok", name: "TikTok" },
  { key: "steam", name: "Steam" },
  { key: "twitch", name: "Twitch" },
  { key: "wattpad", name: "Wattpad" },
  { key: "imdb", name: "IMDb" },
];

export const DEFAULT_TRENDING_WEIGHTS: WeightObject = {
  category: "all",
  name: "Default weights",
  weights: platforms.map(platform => (
    {
      field: platform.key,
      label: platform.name,
      weight: 1.0,
      enabled: true,
    }
  )),
};

export const DEFAULT_TRENDING_FILTERS: FilterObject = {
  category: "all",
  name: "Untitled filters",
  filters: [
    {
      field: "ip",
      label: "Title contains",
      filterType: FilterType.Text,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Text,
      },
    },
    {
      field: "vertical",
      label: "Category",
      value: ["all"],
      filterType: FilterType.Text,
      filterOperation: FilterOperation.Equals,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        setOptions: verticalOptionsWithAll,
      },
    },
    {
      field: "ip_list",
      label: "IP list",
      value: ["Pantheon"],
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        setOptions: IP_LIST,
      },
    },
    { 
      label: "Release date", 
      field: "release_date", 
      filterType: FilterType.DateString, 
      filterOperation: FilterOperation.LessThan,
      valueJoinOperation: FilterJoinOperation.And,
      controlOptions: {
        type: FilterControlType.Date,
      },
    },
    { 
      label: "Franchise", 
      field: "franchise", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: true,
        selectMultiple: true,
      },
    },
    {
      label: "Genre",
      field: "genre",
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
      },
    },
    { 
      label: "Provider", 
      field: "providers", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: true,
        selectMultiple: true,
        filterVertical: Vertical.Movies,
      },
      group: "Movies and Series",
    },
    { 
      label: "Distributor", 
      field: "distributors", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Movies,
      },
      group: "Movies and Series",
    },
    { 
      label: "Production", 
      field: "production", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Movies,
      },
      group: "Movies and Series",
    },
    { 
      label: "Platform", 
      field: "platform", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Developer", 
      field: "developer", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Publisher", 
      field: "publisher", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: true,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Game Mode", 
      field: "game_modes", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Player Perspective", 
      field: "player_perspectives", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
    { 
      label: "Theme", 
      field: "themes", 
      filterType: FilterType.Object,
      filterOperation: FilterOperation.Contains,
      valueJoinOperation: FilterJoinOperation.Or,
      controlOptions: {
        type: FilterControlType.Set,
        autoComplete: false,
        allowBlank: false,
        selectMultiple: true,
        filterVertical: Vertical.Games,
      },
      group: "Games",
    },
  ],
};

export const weightsObjectToWeightageValues = (weightsObject: WeightObject) => {
  const newWeightageValues = {};
  weightsObject.weights.forEach((weight) => {
    newWeightageValues[weight.field] = weight.enabled
      ? parseFloat(weight.weight as any)
      : 0.0;
  });
  return newWeightageValues;
};

export const combineMappedResponses = (data1, data2) => {
  let value = {};
  Object.keys(data1).forEach((key) => {
    switch (key) {
      case "total":
        value[key] = data1[key];
        break;
      case "results":
        value[key] = data1[key].concat(data2[key]);
        break;
      default:
        value[key] = Array.from(new Set([...data1[key], ...data2[key]]));
        break;
    }
  });

  return value;
};