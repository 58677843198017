import React, { useEffect, useMemo, useState } from "react";
import { TileData } from "../../../../components/title-tiles/types";
import TitleTiles from "../../../../components/title-tiles/TitleTiles";
import { Spinner } from "@cloudscape-design/components";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaGoogle, FaInstagram, FaReddit, FaTiktok, FaWikipediaW, FaXTwitter, FaYoutube, FaSteam, FaTwitch, FaImdb } from "react-icons/fa6";
import { TbBrandAo3 } from "react-icons/tb";
import { SiFandom, SiWattpad } from "react-icons/si";
import { combineMappedResponses, platforms } from "../constants";
import { useGetTrackedRankingData } from "../api/hooks/useGetTrackedRankingData";
import moment from "moment";

const platformIconMap = {
  twitter: <FaXTwitter className="h-4 w-4" />,
  google: <FaGoogle className="h-4 w-4" />,
  wiki: <FaWikipediaW className="h-4 w-4" />,
  a03: <TbBrandAo3 className="h-4 w-4" />,
  youtube: <FaYoutube className="h-4 w-4" />,
  reddit: <FaReddit className="h-4 w-4" />,
  fandom: <SiFandom className="h-4 w-4" />,
  instagram: <FaInstagram className="h-4 w-4" />,
  tiktok: <FaTiktok className="h-4 w-4" />,
  steam: <FaSteam className="h-4 w-4" />,
  twitch: <FaTwitch className="h-4 w-4" />,
  wattpad: <SiWattpad className="h-4 w-4" />,
  imdb: <FaImdb className="h-4 w-4" />,
};

export const RankingTileView = ({
  ipList,
  filterQuery,
  weightageValues,
  enabledWeightColumns=[],
  dateRange,
}) => {

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState(null);

  const {
    error,
    data,
    isLoading,
  } = useGetTrackedRankingData({
    startTs: dateRange?.startDate ? moment.utc(dateRange.startDate).startOf("day").unix() : null,
    endTs: dateRange?.endDate ? moment.utc(dateRange.endDate).startOf("day").unix() : null,
    ipList,
    offset: currentPageIndex * pageSize,
    limit: pageSize,
    //sort: sortField,
    //sortOrder: sortOrder,
    weights: weightageValues,
    filters: filterQuery,
  });

  const tileItemData: Array<TileData> = useMemo(() => {
    if (!listData) return [];

    return listData.results.map(item => (
      {
        ipId: item.ip_id,
        title: item.ip,
        vertical: item.vertical,
        releaseDate: item.release_date,
        imageUrl: item.image_url,
        rank: item.rank,
        rankChange: item.rank_change,
        content: (
          <div className="flex flex-col gap-2 w-full h-full overflow-y-hidden">
            <div className={`rounded-md px-2 py-1 text-sm font-semibold border-2 border-slate-600 text-slate-200 flex justify-center gap-1`}>
              Score: <span className="font-bold text-yellow-300">{parseFloat(item.score).toFixed(3)}</span>
            </div>
            <div className="flex flex-col gap-y-1 text-slate-200 flex-wrap w-full content-between grow max-h-[85%] overflow-hidden">
              {platforms.map(platform => (
                enabledWeightColumns.includes(platform.key) && item[platform.key] != null && item[platform.key] > 0 && (
                  <div className="text-xs flex items-center rounded-md w-fit">
                    <span className="p-1 rounded-l-md text-slate-400" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>{platformIconMap[platform.key]}</span>
                    <span className="p-1 rounded-r-md font-bold text-slate-400 w-full" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>{`${parseFloat(item[platform.key]).toFixed(3)}`}</span>
                  </div>
                )
              ))}
            </div>
          </div>
        ),
      } as TileData
    ));
  }, [listData, hasMore]);

  const fetchMoreData = () => {
    if (!isLoading && hasMore) {
      setCurrentPageIndex((prevIndex) => prevIndex + 1);
    }
  };

  useEffect(() => {
    if (!data?.data?.results) return;
    if (currentPageIndex === 0) {
      setListData(data.data);
    } else {
      setListData((prevItems) => combineMappedResponses(prevItems, data.data));
    }
    setHasMore((listData?.results?.length ?? 0) + data.data.results.length < data.data.total);
  }, [data]);

  useEffect(() => {
    setCurrentPageIndex(0);
    setHasMore(true);
  }, [dateRange, filterQuery, weightageValues, enabledWeightColumns]);

  return (
    (isLoading && currentPageIndex === 0) ? (
      <div className="w-full h-96 flex items-center justify-center">
        <Spinner size="large" />
      </div>
    ) : error ? (
      <div className="w-full h-96 flex items-center justify-center text-lg text-red-500">
        Error fetching data
      </div>
    ) : (
      <InfiniteScroll
        style={{ overflow: "100%" }}
        dataLength={listData?.results?.length || 0}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center my-10">
            <Spinner size="large" />
          </div>
        }
      >
        <TitleTiles
          variant="grid"
          itemData={tileItemData}
        />
      </InfiniteScroll>
    )
  );
};