import React, { useState } from "react";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { Tabs } from "@cloudscape-design/components";
import { useSearchParams } from "react-router-dom";
import { JobList } from "./components/JobList/JobList";
import { CreateReportWizard } from "./components/CreateReportWizard/CreateReportWizard";
import { COLUMN_DEFINITIONS, listOptions } from "./constants";

const ReportBuilder: React.FC = () => {

  const title = "Report Builder";
  const breadcrumbs = [
    { text: "Tools" },
    { 
      text: title, 
      onClick: () => {
        setWizardVisible(false);
      } 
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const [listTabId, setListTabId] = useState(listOptions.find(x => x.value === searchParams.get("list"))?.value || "personal");
  const [wizardVisible, setWizardVisible] = useState(false);
  const [wizardDefaults, setWizardDefaults] = useState<any>(null);

  return (
    <Layout
      contentType="table"
      title={title}
      navItems={navItems as NavItemsWithId}
      breadcrumbs={[...breadcrumbs, ...(wizardVisible ? [{ text: "Create Report" }] : [])] as any}
      content={
        <div>
          {wizardVisible ? (
            <CreateReportWizard 
              setWizardVisible={setWizardVisible}
              wizardDefaults={wizardDefaults}
            />
          ) : (
            <div className="flex flex-col gap-4">
              <Tabs
                onChange={({ detail }) => {
                  setListTabId(detail.activeTabId);
                  setSearchParams({ list: detail.activeTabId });
                }}
                activeTabId={listTabId}
                tabs={[
                  {
                    label: "My reports",
                    id: "personal",
                    content: (null),
                  },
                  {
                    label: "Organization reports",
                    id: "organization",
                    content: (null),
                  },
                  {
                    label: "Shared with me",
                    id: "shared",
                    content: (null),
                  }
                ]}
                disableContentPaddings
              />
              <JobList
                listTabId={listTabId as "personal" | "organization" | "shared"}
                setWizardVisible={setWizardVisible}
                setWizardDefaults={setWizardDefaults}
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default ReportBuilder;