
const rankingList = [
  {
    path: "/ranking/global",
    label: "Rankings Overview",
    description: "Get a sense of the top and trending titles across a limited set of platform metrics",
    parent: "ranking",
  },
  {
    path: "/dashboards/globalranking/movies",
    label: "Movies",
    description: "View rankings of all movies based on a limited set of platform metrics",
    parent: "ranking",
  },
  {
    path: "/dashboards/globalranking/series",
    label: "Series",
    description: "View rankings of all series based on a limited set of platform metrics",
    parent: "ranking",
  },
  {
    path: "/dashboards/globalranking/gaming",
    label: "Games",
    description: "View rankings of all games based on a limited set of platform metrics",
    parent: "ranking",
  },
  {
    path: "/dashboards/raw-data",
    label: "Tracked Titles",
    description: "View rankings of select titles based on an extended set of platform metrics",
    parent: "ranking",
  },
];

const dashboardList = [
  {
    path: "/dashboards/wizard",
    label: "Personal Dashboard",
    description: "Customizable page view populated from a library of data widgets",
    parent: "dashboards",
  },
  // {
  //   path: "/dashboards/netflix",
  //   label: "Netflix Dashboard",
  //   description: "Based on public data from Netflix",
  //   parent: "dashboards",
  // },
  {
    path: "/dashboards/nonott",
    label: "Non-OTT Titles",
    description: "Titles beyond OTT platforms",
    parent: "dashboards",
  },
  {
    path: "/dashboards/news",
    label: "News River",
    description: "Stream of news articles from hand-picked sources related to entertainment",
    parent: "dashboards",
  },
  {
    path: "/dashboards/genres",
    label: "Genre Dashboard",
    description: "Titles by genre",
    parent: "dashboards",
  }
];

const platformsList = [
  {
    path: "/platforms/global",
    label: "Pantheon",
    description: "Ranked list of titles with summary metrics built on data from all platforms using default category weights",
    parent: "platforms",
  },
  {
    path: "/platforms/wikipedia",
    label: "Wikipedia",
    description: "Ranked list of titles with summary metrics built on page views",
    parent: "platforms",
  },
  {
    path: "/platforms/google",
    label: "Google",
    description: "Google search volume for titles broken down into categories of intent and more",
    parent: "platforms",
  },
  {
    path: "/platforms/imdb",
    label: "IMDb",
    description: "Ranked list of titles with summary metrics built on votes and ratings",
    parent: "platforms",
  },
  {
    path: "/platforms/rottentomatoes",
    label: "Rotten Tomatoes",
    description: "Ranked list of titles with summary metrics built on audience and critic scores",
    parent: "platforms",
  },
  {
    path: "/platforms/twitch",
    label: "Twitch",
    description: "Ranked list of titles with summary metrics built on broadcasters, viewers and followers",
    parent: "platforms",
  },
  {
    path: "/platforms/steam",
    label: "Steam",
    description: "Ranked list of titles with summary metrics built on player count and reviews",
    parent: "platforms",
  },
  {
    path: "/platforms/youtube",
    label: "YouTube",
    description: "Ranked list of titles with summary metrics built on official trailer views, likes and comments",
    parent: "platforms",
  },
  {
    path: "/platforms/piracy",
    label: "Piracy",
    description: "Ranked list of titles with summary metrics built on torrent downloads",
    parent: "platforms",
  },
];

const accountManagementList = [
  {
    path: "/users",
    label: "Users",
    description: "Manage users and their passwords",
    parent: "account-management",
  },
  {
    path: "/roles",
    label: "Roles",
    description: "Manage roles and their permissions",
    parent: "account-management",
  },
  {
    path: "/permissions",
    label: "Permissions",
    description: "Manage permissions",
    parent: "account-management",
  },
  {
    path: "/teams",
    label: "Teams",
    description: "Manage teams and their members",
    parent: "account-management",
  }

];

const topicModellingList = [
  {
    path: "/topic-modeling/pantheon",
    label: "General",
    description: "General topics leveraging clustered data sets from YouTube and Reddit",
    parent: "topic-modeling",
  },
  {
    path: "/topic-modeling/true-crime",
    label: "True Crime",
    description: "True crime topics leveraging clustered data sets from YouTube and Reddit",
    parent: "topic-modeling",
  },
];

const toolsList = [
  {
    path: "/tools/compare",
    label: "Compare",
    description: "Compare the performance of titles over time across multiple datapoints",
    parent: "tools",
  },
  /*{
    "path": "/tools/google-search-trends",
    "label": "Google Search Trends",
    "description": "Explore real-time search data from Google Trends with more room for comparison",
    "parent": "tools"
  },*/
  {
    path: "/tools/twitter-query-trends",
    label: "Twitter Query Trends",
    description: "Track and analyze real-time and historical query trends on Twitter",
    parent: "tools",
  },
  {
    path: "/dashboards/jwstreaming",
    label: "JustWatch Charts",
    description: "JustWatch streaming charts based on user activity on the site",
    parent: "tools",
  },
  {
    path: "/box-office",
    label: "Box Office Analysis",
    description: "Comprehensive box office performance analysis and financial performance of films across various markets",
    parent: "tools",
  },
  {
    path: "/tools/report-builder",
    label: "Report Builder",
    description: "Generate exportable reports based on data within Pantheon for any number of titles",
    parent: "tools",
  },

];

const exploreList = [
  {
    path: "/explore/movies",
    label: "Movies",
    description: "Search for movies in Pantheon",
    parent: "explore",
  },
  {
    path: "/explore/television",
    label: "Series",
    description: "Search for series in Pantheon",
    parent: "explore",
  },
  {
    path: "/explore/games",
    label: "Games",
    description: "Search for games in Pantheon",
    parent: "explore",
  },
  {
    path: "/explore/talent",
    label: "Talent",
    description: "Search for talent in Pantheon",
    parent: "explore",
  },
  {
    path: "/explore/franchise",
    label: "Franchises",
    description: "Search for franchises in Pantheon",
    parent: "explore",
  },
  {
    path: "/explore/companies",
    label: "Companies",
    description: "Search for studios, distributors, developers and publishers in Pantheon",
    parent: "explore",
  },
];

const youtubeList = [
  {
    path: "/youtube",
    label: "YouTube Overview",
    description: "Overview of YouTube data including channels, trailers, and more",
    parent: "youtube",
  },
  {
    path: "/youtube/trends",
    label: "YouTube Trends",
    description: "YouTube trends",
    parent: "youtube",
  },
  {
    path: "/youtube/channels",
    label: "YouTube Channels",
    description: "YouTube channels",
    parent: "youtube",
  },
  {
    path: "/youtube/movietrailers",
    label: "Movie Trailers",
    description: "Movie trailers from YouTube with detailed data sets",
    parent: "youtube",
  },
  {
    path: "/youtube/seriestrailers",
    label: "TV Show Trailers",
    description: "TV show trailers from YouTube with detailed data sets",
    parent: "youtube",
  },
  {
    path: "/youtube/gamestrailers",
    label: "Game Trailers",
    description: "Game trailers from YouTube with detailed data sets",
    parent: "youtube",
  },
  {
    path: "/youtube/kidstrailers",
    label: "Kids Trailers",
    description: "Kids content trailers from YouTube with detailed data sets",
    parent: "youtube",
  },
  {
    path: "/youtube/unmappedtrailers",
    label: "Unmapped Trailers",
    description: "Trailers that are not mapped to any title",
    parent: "youtube",
  },
  {
    path: "/youtube/alltrailers",
    label: "All Trailers",
    description: "All trailers from YouTube regardless of category",
    parent: "youtube",
  },
];


export const servicesList = [
  { key: "favorites", label: "Favorites" },
  { key: "ranking", label: "Rankings", items: rankingList },
  { key: "dashboards", label: "Dashboards", items: dashboardList },
  { key: "platforms", label: "Platforms", items: platformsList },
  {
    key: "topicModelling",
    label: "Topic Modeling",
    items: topicModellingList,
  },
  { key: "tools", label: "Tools", items: toolsList },
  {
    key: "explore",
    label: "Explore",
    items: exploreList,
  },
  {
    key: "youtube",
    label: "YouTube",
    items: youtubeList,
  },
  {
    key: "account-management",
    label: "Account Management",
    items: accountManagementList,
  },
];
