import {
  Button,
  ButtonDropdown,
  Container,
  CopyToClipboard,
  ExpandableSection,
  FormField,
  Header,
  SegmentedControl,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { IP_LIST, TAGS_LIST } from "../../../../config-global";
import { addQueryParams, getQueryParams } from "../../../../utils/queryUtils";
import { useAddYoutubeVideos } from "../../api/hooks/useAddYoutubeVideos";
import CastAndCrewComponent from "../components/CastAndCrewComponent";
import { GoogleTitleInsights } from "../components/GoogleTitleInsights";
import { News } from "../components/News";
import ProdnDistrib from "../components/ProductionnDistribution";
import StreamingWindowComponent from "../components/StreamingWindow";
import TagList from "../components/TagList";
import TagsAndIDsComponent from "../components/TagsAndIDsComponent";
import TrailerGrid from "../components/Trailer/Trailer";
import { RankTrends } from "../components/Trends/RankTrends";
import WikiSummaryBox from "../components/WikipediaTab";
import { AddToFranchiseModal } from "../components/modals/AddToFranchiseModal";
import { AddYoutubeTrailerModal } from "../components/modals/AddYoutubeTrailerModal";
import AttributesEditModal from "../components/modals/AttributesEditModal";
import AuditModal from "../components/modals/AuditModal";
import { CompareModal } from "../components/modals/CompareModal/CompareModal";
import { DeleteConfirmationModal } from "../components/modals/DeleteConfirmationModal";
import { TrackUnTrackConfirmationModal } from "../components/modals/TrackUnTrackConfirmationModal";
import { MdOutlinePlaylistAdd, MdOutlinePlaylistRemove } from "react-icons/md";
import GlobalScore from "../components/Scores/GlobalScore";
import { DateRange } from "../components/DateRange";
import BookScore from "./components/BookScore";
import { calculateDateRange } from "../../utils";

export default function ItemBook({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
}) {
  const { user } = useAuthContext();
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAddYoutubeTrailerModal, setShowAddYoutubeTrailerModal] =
    useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [isTracked, setIsTracked] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const onShowCompareModalInit = () => setShowCompareModal(true);
  const [triggerTrailersApi, setTriggerTrailersApi] = useState(true);
  const onShowAuditInit = () => setShowAuditModal(true);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const [activeTabId, setActiveTabId] = useState(getQueryParams("tab") ?? "trends");
  const [trendsType, setTrendsType] = useState(getQueryParams("chartType") ?? "rank");
  const [dateRange, setDateRange] = useState(calculateDateRange(new Date(Date.parse(itemData.data.release_date))));
  const ratings = itemData?.data?.ratings
  
  useEffect(() => {
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";

  useEffect(() => {
    onShowAttrClose();
  }, [itemData]);


  const onSuccess = () => {
    setTriggerTrailersApi(true);
    setShowAddYoutubeTrailerModal(false);
  };

  const { mutate: addYoutubeVideos } = useAddYoutubeVideos(onSuccess);

  const handleTabChange = ({ detail }) => {
    setActiveTabId(detail.activeTabId);
    addQueryParams({ tab: detail.activeTabId });
  };

  return (
    <Container
      variant="borderless"
      disableContentPaddings
      disableHeaderPaddings
      header={
        <Header
          className="mb-4"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="Track Title"
                variant="normal"
                iconSvg={<span><MdOutlinePlaylistAdd className="w-full h-full scale-125" /></span>}
                onClick={() => {
                  setAddTrack(true);
                  onTrackUnTrackInit();
                }}
                disabled={isAdmin ? itemData?.data?.ip_list?.length >= IP_LIST.length : true}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to track this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Untrack Title"
                variant="normal"
                iconSvg={<span><MdOutlinePlaylistRemove className="w-full h-full scale-125" /></span>}
                onClick={() => {
                  setAddTrack(false);
                  onTrackUnTrackInit();
                }}
                disabled={isAdmin ? !itemData?.data?.tracked : true}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to untrack this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Edit Tags"
                variant="normal"
                iconName="edit"
                onClick={() => onShowAttrInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin ? "You don't have permission to edit tags." : ""
                }
              />
              <Button
                ariaLabel="Delete Title"
                iconName="remove"
                variant="normal"
                onClick={() => onDeleteModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to delete this title."
                    : ""
                }
              />
              <ButtonDropdown
                items={[
                  { text: "Add to franchise", id: "add-to-franchise", disabled: !isAdmin, disabledReason: "You don't have permission to add titles to franchises" },
                  { text: "Compare with other titles", id: "compare" },
                  { text: "View audit logs", id: "audit-logs" },
                ]}
                onItemClick={({ detail }) => {
                  switch (detail.id) {
                    case "add-to-franchise":
                      onAddFranchiseModalInit();
                      break;
                    case "compare":
                      onShowCompareModalInit();
                      break;
                    case "audit-logs":
                      onShowAuditInit();
                      break;
                  }
                }}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        >
          <div className="flex items-center">
            <div className="text-xl font-bold inline-block">
              {itemData.data?.ip}
            </div>
            <div className="inline-block whitespace-nowrap ml-2">
              {isTracked && (
                <div className="flex space-x-2">
                  {itemData.data?.ip_list?.length > 0 ? (
                    itemData.data.ip_list.map((ip) => (
                      <span
                        key={ip}
                        className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                      >
                        <svg
                          className="h-1.5 w-1.5 fill-green-400"
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        {ip}
                      </span>
                    ))
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                      Tracked
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="dark:text-slate-400 text-slate-600 text-sm font-normal">
            {[
              itemData.data?.vertical,
              ...(itemData.data.release_date
                ? [
                  moment.unix(itemData.data.release_date/1000).format("LL")
                ]
                : []),
              `Edition count: ${itemData.data?.edition_count}`,
            ].join(" \u2022 ")}
          </div>
        </Header>
      }
    >
      <Container variant="borderless" disableContentPaddings>
        <div className="space-y-2">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="w-full rounded-md sm:w-auto">
              {itemData.data.image_url ? (
                <div className="w-64 h-auto">
                  <img
                    src={itemData.data?.image_url}
                    alt="..."
                    className="w-64 drop-shadow-lg h-min rounded-md"
                  />
                </div>
              ) : (
                <div className="h-full w-60 flex items-center justify-center rounded-md bg-gray-400">
                  <span className="text-white font-extrabold text-4xl">?</span>
                </div>
              )}
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-col gap-1">
                <div className="mb-2">
                  <SpaceBetween direction="horizontal" size="xl">
                    {itemData.data?.global_rank?.pantheon && (
                      <GlobalScore
                        rank={itemData.data.global_rank?.pantheon?.weekly_rank}
                        rankChange={itemData.data.global_rank?.pantheon?.weekly_rank_change}
                        vertical={itemData.data.vertical}
                      />
                    )}
                  </SpaceBetween>
                </div>
                {itemData.data?.author && (
                  <TagList
                    title="Author"
                    tagData={[{
                      label: itemData.data?.author,
                      color: "indigo",
                    }] ?? []}
                  />
                )}
                {itemData.data?.publisher && (
                  <TagList
                    title="Publisher"
                    tagData={[{
                      label: itemData.data?.publisher,
                      color: "red",
                    }] ?? []}
                  />
                )}
                <div className="flex gap-3 items-start">
                  {itemData.data?.genre && itemData.data.genre.length > 0 && (
                    <TagList
                      title="Genres"
                      tagData={itemData.data.genre?.map((genre) => ({
                        label: genre,
                        color: "purple",
                      })) ?? []}
                    />
                  )}
                  {itemData.data.cTags && itemData.data.cTags.length > 0 && (
                    <>
                      <div className="w-0 h-full border-r dark:border-slate-600 border-slate-400"></div>
                      <TagList
                        title="Tags"
                        tagData={itemData.data.cTags.map((cTags) => ({
                          label: cTags,
                          color: "green",
                        }))}
                      />
                    </>
                  )}
                </div>
                <div className="flex gap-3 items-start">
                {itemData.data?.isbn && (
                  <FormField 
                    label={<span className="dark:text-slate-200 text-black font-bold text-xs text-nowrap">ISBN</span>} 
                    stretch 
                  >
                    <CopyToClipboard
                      copyButtonAriaLabel="Copy ISBN"
                      copyErrorText="ISBN failed to copy"
                      copySuccessText="ISBN copied"
                      textToCopy={itemData.data.isbn}
                      variant="inline"
                    />
                  </FormField>
                )}
                {itemData.data?.isbn13 && (
                  <>
                    <div className="w-0 h-full border-r dark:border-slate-600 border-slate-400"></div>
                    <FormField 
                      label={<span className="dark:text-slate-200 text-black font-bold text-xs text-nowrap">ISBN-13</span>} 
                      stretch 
                    >
                      <CopyToClipboard
                        copyButtonAriaLabel="Copy ISBN-13"
                        copyErrorText="ISBN-13 failed to copy"
                        copySuccessText="ISBN-13 copied"
                        textToCopy={itemData.data.isbn13}
                        variant="inline"
                      />
                    </FormField>
                  </>
                )}
                </div>    
                {itemData.data.franchise &&
                  itemData.data.franchise.length > 0 && (
                    <TagList
                      title="Franchises"
                      tagData={itemData.data.franchise.map((franchise) => ({
                        label: franchise.name,
                        color: "green",
                        href: `/item/${franchise.id}`,
                      }))}
                    />
                  )}
                {itemData.data.production &&
                  itemData.data.production.length > 0 && (
                    <TagList
                      title="Production companies"
                      tagData={itemData.data.production.map((company) => ({
                        label: company.name,
                        color: "red",
                        href: `/item/companies-${company.id}`,
                      }))}
                    />
                  )}
                {(() => {
                  const theatricalDistributors =
                    itemData.data.distributors?.filter((distributor) =>
                      distributor.formats.includes("theatrical"),
                    ) || [];
                  const uniqueDistributors = Array.from(
                    new Set(theatricalDistributors.map((d) => d.name)),
                  ).map((name) =>
                    theatricalDistributors.find((d) => d.name === name),
                  );

                  return uniqueDistributors.length > 0 ? (
                    <TagList
                      title="Theatrical distributors"
                      tagData={uniqueDistributors.map((distributor) => ({
                        label: distributor.name,
                        color: "blue",
                        href: `/item/companies-${distributor.id}`,
                      }))}
                    />
                  ) : null;
                })()}
              </div>
              <div className="pt-4">
              <SpaceBetween direction="horizontal" size="xl">
                {ratings?.amazonbooks && <BookScore platform={"amazonbooks"} title={"Amazon Books"} rating={ratings.amazonbooks} link={itemData?.data?.amazon_url}/>}
                {ratings?.goodreads && <BookScore platform={"goodreads"} title={"Goodreads"} rating={ratings.goodreads} link={itemData?.data?.goodreads_url}/>}
                {ratings?.barnesnoble && <BookScore platform={"barnesnoble"} title={"Barnes & Noble"} rating={ratings.barnesnoble} link={itemData?.data?.barnesnoble_url}/>}
              </SpaceBetween>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex space-x-2 mt-4">
        <div className="w-full ">
          <Tabs
            onChange={handleTabChange}
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: (
                  <SpaceBetween direction="vertical" size="m">
                    <Header
                      actions={
                        <SpaceBetween direction="horizontal" size="s">
                          <SegmentedControl
                            selectedId={trendsType}
                            onChange={({ detail }) => {
                              setTrendsType(detail.selectedId);
                              addQueryParams({ chartType: detail.selectedId });
                            }}
                            options={[
                              { id: "rank", text: "Rank trends" },
                              { id: "datapoint", text: "Advanced trends" },
                            ]}
                          />
                          <DateRange dateRange={dateRange} setDateRange={setDateRange} />
                        </SpaceBetween>
                      }
                    >
                      
                    </Header>
                    <RankTrends
                      itemData={itemData}
                      dateRange={dateRange}
                      configType={trendsType === "rank" ? "basic" : "advanced"}
                    />
                  </SpaceBetween>
                ),
              },
              {
                label: "Google Search",
                id: "google",
                content: (
                  <GoogleTitleInsights
                    titleData={itemData.data}
                  />
                ),
              },
              {
                label: "Summary",
                id: "wiki",
                content: (
                  <WikiSummaryBox
                    id={itemData.data?.wikidata_id}
                    //here we should set the wikidata_id when available or different id
                    summary={itemData.data?.summary}
                    plotLong={itemData.data?.plotLong}
                  />
                ),
              },
              {
                label: "News",
                id: "news",
                content: (
                  <Container
                    header={
                      <Header>Recent News</Header>
                    }
                  >
                    <News 
                      ip={itemData.data.ip}
                      ip_id={itemData.data.ip_id} 
                    />
                  </Container>
                ),
              },
              {
                label: "Cast & Crew",
                id: "CCT",
                content: (
                  <CastAndCrewComponent itemData={itemData.data} />
                ),
              },
              {
                label: "IDs",
                id: "tags",
                content: (
                  <ExpandableSection
                    defaultExpanded
                    headingTagOverride="h4"
                    variant="container"
                    headerText="IDs"
                  >
                    <TagsAndIDsComponent itemData={itemData.data} />
                  </ExpandableSection>
                ),
              },
              {
                label: "Trailers",
                id: "Trailers",
                content: (
                  <TrailerGrid
                    id={itemData.data.ip_id}
                    triggerTrailersApi={triggerTrailersApi}
                    setTriggerTrailersApi={setTriggerTrailersApi}
                    setShowAddYoutubeTrailerModal={
                      setShowAddYoutubeTrailerModal
                    }
                  />
                ),
              },
              ...(itemData.data.distributors &&
                itemData.data.distributors.length > 0
                ? [
                  {
                    label: "Distribution",
                    id: "PD",
                    content: (
                      <ProdnDistrib
                        distributors={itemData.data.distributors}
                      />
                    ),
                  },
                ]
                : []),
              {
                label: "Box Office",
                id: "BO",
                content: (
                  <SpaceBetween direction="vertical" size="s">
                  </SpaceBetween>
                ),
              },
              {
                label: "Streaming Window",
                id: "tl",
                content: (
                  <SpaceBetween direction="vertical" size="xl">
                    <StreamingWindowComponent
                      titleId={itemData.data.imdb_id}
                      vertical={itemData.data.vertical}
                    />
                  </SpaceBetween>
                ),
              },
            ]}
          />
        </div>
      </div>
      <TrackUnTrackConfirmationModal
        visible={showTrackUnTrackModal}
        ipLists={IP_LIST}
        track={!addTrack}
        itemData={itemData?.data}
        ip_id={itemData?.data?.ip_id}
        defaultSelectedIps={itemData?.data?.ip_list}
        onDiscard={onTrackUnTrackClose}
        consentText={itemData?.data?.name}
        onConfirm={(item) => {
          if (item.track) {
            onUntrackItem(item);
            onTrackUnTrackClose();
          } else {
            onTrackItem(item);
            onTrackUnTrackClose();
          }
        }}
      />
      <DeleteConfirmationModal
        instances={[itemData?.data]}
        visible={showDeleteModal}
        onDiscard={onDeleteModalClose}
        onDelete={() => onDelete(itemData?.data?.ip_id)}
        consentText={itemData?.data?.name}
      />
      <AddToFranchiseModal
        visible={showAddFranchiseModal}
        onDiscard={onAddFranchiseModalClose}
        itemData={itemData}
      />
      <AddYoutubeTrailerModal
        visible={showAddYoutubeTrailerModal}
        onSubmit={(videoIds) => {
          addYoutubeVideos({
            videoIds,
            ip: itemData?.data?.ip,
            ipId: itemData?.data?.ip_id,
          });
        }}
        onDiscard={() => setShowAddYoutubeTrailerModal(false)}
      />
      <AttributesEditModal
        visible={showAttrModal}
        itemData={itemData?.data}
        onDismiss={() => setShowAttrModal(false)}
        editableAttributes={TAGS_LIST.map((x) => x.value).filter(
          (x) => !["youtubeChannel", "twitch", "discord", "steam"].includes(x),
        )}
      />
      <AuditModal
        visible={showAuditModal}
        ip_id={itemData?.data?.ip_id}
        onDiscard={() => setShowAuditModal(false)}
      />
      <CompareModal
        visible={showCompareModal}
        setVisible={setShowCompareModal}
        titleId={itemData?.data?.ip_id}
      />
    </Container>
  );
}
