import React from "react";
import { ButtonDropdown } from "@cloudscape-design/components";
import { getTrendingRankingDataV3 } from "../api/request";

export const ExportButton = ({
  filterQuery,
  startTs,
  endTs,
  weightageValues,
}) => {

  const [isLoading, setIsLoading] = React.useState(false);

  const exportData = (format) => {
    setIsLoading(true);

    getTrendingRankingDataV3({
      filters: filterQuery,
      startTs,
      endTs,
      weights: weightageValues,
      download: format,
    }).then(data => {
      const url = data.data;
      window.location.href = url;
      setIsLoading(false);
    });
  };

  return (
    <ButtonDropdown
      items={[
        { text: "Data as CSV", id: "csv" },
        { text: "Data as XLSX", id: "xlsx" },
      ]}
      onItemClick={({ detail }) => exportData(detail.id)}
      loading={isLoading}
    >
      Export
    </ButtonDropdown>
  );
};