import React, { useEffect, useMemo, useState } from "react";
import { ColumnControlType, ColumnDefinition } from "../../constants";
import { Button, ColumnLayout, Container, DatePicker, FormField, Header, Icon, Popover, Select, SpaceBetween } from "@cloudscape-design/components";
import { EditableTextAttribute } from "../../../../ranking/components/EditableTextAttribute";
import { useCreateReportContext } from "./hooks/useCreateReportContext";
import { BetterExpandableSection } from "../../../../dashboards/news-dashboard/components/BetterExpandableSection";
import { RangeDateSelector } from "../../../../../components/RangeDateSelector";
import moment from "moment";
import { VerticalIcon } from "../../../../../components/VerticalIcon";
import { FaSliders } from "react-icons/fa6";
import { Vertical } from "../../../../../types/verticalTypes";
import { FaRegCheckCircle } from "react-icons/fa";
import _ from "lodash";

interface ColumnDefinitionItemProps {
  definition: ColumnDefinition;
  itemIndex?: number;
  variant?: "default" | "compact";
  dragHandleProps?: any;
  latestTimes?: any;
}

export const ColumnDefinitionItem: React.FC<ColumnDefinitionItemProps> = ({
  definition,
  itemIndex,
  variant="default",
  dragHandleProps={},
  latestTimes={},
}) => {
  const { 
    columnDefinitions, 
    setColumnDefinitions, 
    lastDateRangeValue, 
    setLastDateRangeValue, 
    lastDateValue, 
    setLastDateValue,
  } = useCreateReportContext();

  const [controlGroup, setControlGroup] = useState<string | null>(definition.options?._controlGroup ?? null);

  const icons = useMemo(() => {
    const iconElements = { verticals: [], attributes: [] };
    if (definition.verticals?.length > 0) {
      const verticalLabelMap = {
        [Vertical.Movies]: "Movies",
        [Vertical.Series]: "Series",
        [Vertical.Games]: "Games",
        [Vertical.Franchises]: "Franchises",
        [Vertical.Books]: "Books",
        [Vertical.Talent]: "Talent",
      };
      iconElements.verticals.push(...definition.verticals.map((vertical) => (
        {
          icon: (<VerticalIcon vertical={vertical} key={vertical} />),
          text: verticalLabelMap[vertical],
        }
      )));
    }
    if (definition.controlOptions?.length > 0) {
      iconElements.attributes.push(
        {
          icon: (<FaSliders />),
          text: "Configurable",
          hoverText: "This column contains options that can be configured",
        }
      );
    }
    if (definition.trackedOnly) {
      iconElements.attributes.push(
        {
          icon: (<FaRegCheckCircle />),
          text: "Tracked only",
          hoverText: "Values within this column are only available for tracked titles",
        }
      );
    }
    return iconElements;
  }, [definition]);

  const getExistingDateRangeOptions = (option) => {
    let options = columnDefinitions.map((col) => col.controlOptions?.filter((o) => 
      o.type === ColumnControlType.Range && 
      col.options?.[o.optionField] && 
      JSON.stringify(col.options?.[o.optionField]) !== JSON.stringify(definition.options?.[option.optionField]))
      .map((o) => (
        { 
          value: JSON.stringify(col.options[o.optionField]), 
          label: col.options[o.optionField].type === "relative" ? `Last ${col.options[o.optionField].amount} ${col.options[o.optionField].unit}s` : `${col.options[o.optionField].startDate} - ${col.options[o.optionField].endDate}` 
        }
      )) ?? []).flat();
    options = options.filter((o, index) => options.findIndex((oo) => oo.value === o.value) === index);
    return options;
  };

  const getExistingDateOptions = (option) => {
    let options = columnDefinitions.map((col) => col.controlOptions?.filter((o) => 
      o.type === ColumnControlType.Date && 
      col.options?.[o.optionField] && 
      JSON.stringify(col.options?.[o.optionField]) !== JSON.stringify(definition.options?.[option.optionField]))
      .map((o) => (
        { 
          value: JSON.stringify(col.options[o.optionField]), 
          label: col.options[o.optionField].endDate
        }
      )) ?? []).flat();
    options = options.filter((o, index) => options.findIndex((oo) => oo.value === o.value) === index);
    return options;
  };

  useEffect(() => {
    if (definition.controlOptions?.length > 0) {
      const newDefinition = { ...definition };

      if (!newDefinition.options) newDefinition.options = {};

      const controlSwitch = newDefinition.options._controlGroup !== controlGroup;

      newDefinition.controlOptions.forEach((option) => {
        if (option.group && option.group !== newDefinition.options._controlGroup) return;
        if ((!option.group || !controlSwitch || option.optionField === "_controlGroup") && newDefinition.options[option.optionField]) return;

        let startDate = moment().subtract(7, "days");
        let endDate = moment();

        const defaultDateOrRangeValue = option.type === ColumnControlType.Date ? lastDateValue : option.type === ColumnControlType.Range ? lastDateRangeValue : null;

        switch (option.type) {
          case ColumnControlType.Set:
            newDefinition.options[option.optionField] = option.setOptions.find((setOption) => setOption.value === option.defaultSetOption)?.value;
            break;
          case ColumnControlType.Date:
            startDate = moment();
            newDefinition.options.aggregation = "sum"; // Funny silly compatibility hack
          case ColumnControlType.Range:
            const startDateBounds = (option.bounds?.startDatePath ? moment.utc(_.get(latestTimes, option.bounds.startDatePath) * 1000).format("YYYY-MM-DD") : option.bounds?.startDate) ?? null;
            const endDateBounds = (option.bounds?.endDatePath ? moment.utc(_.get(latestTimes, option.bounds.endDatePath) * 1000).format("YYYY-MM-DD") : option.bounds?.endDate) ?? null;
            if (endDateBounds && endDate.isAfter(moment(endDateBounds))) {
              endDate = moment(endDateBounds);
            }
            if (startDateBounds && startDate.isBefore(moment(startDateBounds))) {
              startDate = moment(startDateBounds);
            }
            const diff = endDate.startOf("day").diff(startDate.startOf("day"), "days");
            if (diff < 7) {
              startDate = startDate.subtract(7 - diff - 1, "days");
            }
            if (option.isDateEnabled != null) {
              for (let i = 0; i < 100; i++) {
                if (option.isDateEnabled(endDate.toDate())) {
                  break;
                }
                endDate = endDate.subtract(1, "day");
              }
              for (let i = 0; i < 100; i++) {
                if (option.isDateEnabled(startDate.toDate())) {
                  break;
                }
                startDate = startDate.subtract(1, "day");
              }
            }
            if (defaultDateOrRangeValue) {
              newDefinition.options[option.optionField] = defaultDateOrRangeValue;
            } else {
              newDefinition.options[option.optionField] = {
                type: (option.isDateEnabled == null && endDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) ? "relative" : "absolute",
                unit: "day",
                amount: endDate.diff(startDate, "days"),
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
              };
            }
            break;
        }
      });

      setControlGroup(newDefinition.options._controlGroup);
      setColumnDefinitions(columnDefinitions.map((col, index) => index === itemIndex ? newDefinition : col));
    }
  }, [definition.controlOptions, definition.options?._controlGroup]);

  return (
    <Container
      header={
        <Header
          variant="h3"
          actions={
            variant === "default" && (
              <>
                {/*<ButtonDropdown
                  variant="icon"
                  items={[
                    { id: "remove", text: "Remove" },
                  ]}
                  onItemClick={({ detail }) => {
                    switch (detail.id) {
                      case "remove":
                        setColumnDefinitions(columnDefinitions.filter((col, index) => index !== itemIndex));
                        break;
                    }
                  }}
                />*/}
                <Button
                  variant="icon"
                  iconName="close"
                  onClick={() => setColumnDefinitions(columnDefinitions.filter((col, index) => index !== itemIndex))}
                />
              </>
            )
          }
        >
          <div className="flex gap-2 items-start w-full" {...dragHandleProps}>
            <div className="mt-[1px]">
              <Icon name="drag-indicator" variant="subtle" />
            </div>
            {variant === "default" ? (
              <EditableTextAttribute 
                value={definition.headerText} 
                onChange={(value) => {
                  setColumnDefinitions(columnDefinitions.map((col, index) => index === itemIndex ? { ...col, headerText: value } : col));
                }} 
              />
            ) : (
              <div className="coldef-header flex gap-1 justify-between items-start w-full">
                <div className="w-fit">{definition.headerText}</div>
                {definition.description ? (
                  <Popover
                    dismissButton={false}
                    position="top"
                    size="large"
                    triggerType="custom"
                    content={definition.description}
                  >
                    <Button 
                      variant="icon"
                      iconName="status-info"
                    />
                  </Popover>
                ) : null}
                <style>{`
                  div[class^='awsui_title_']:has( div[class^='coldef-header']) {
                    width: 100% !important;
                  }
                `}</style>
              </div>
            )}
          </div>
        </Header>
      }
      //disableContentPaddings={variant === "compact" && definition.type === "static"}
    >
      {variant === "default" ? (
        <div className="flex flex-col gap-3">
          <ColumnLayout columns={2}>
            <FormField label="Source">
              <span>{definition.source}</span>
            </FormField>
            <FormField label="Field">
              <span>{definition.field}</span>
            </FormField>
          </ColumnLayout>
          {definition.controlOptions?.length > 0 && (
            <BetterExpandableSection
              header={<b>Options</b>}
              defaultExpanded
              inlineHeaderActions={definition.caveats?.length > 0 && (
                <Popover
                  dismissButton={false}
                  position="top"
                  size="large"
                  triggerType="custom"
                  content={
                    <div>
                      <div className="mb-2 font-bold text-lg">Keep in mind:</div>
                      <ul className="list-disc pl-4">
                        {definition.caveats.map((caveat) => (
                          <li>{caveat}</li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <Button
                    variant="inline-link"
                  >
                    Caveats
                  </Button>
                </Popover>
              )}
            >
              <SpaceBetween direction="vertical" size="xs">
                {definition.controlOptions?.filter((option) => !option.group || option.group === definition.options?._controlGroup).map((option) => (
                  <FormField label={option.label}>
                    {option.type === ColumnControlType.Set ? (
                      <Select
                        options={option.setOptions}
                        selectedOption={option.setOptions.find((o) => o.value === definition.options?.[option.optionField])}
                        onChange={({ detail }) => {
                          const selectedOptionChoice = option.setOptions.find((o) => o.value === detail.selectedOption.value);
                          const newColumnDef = { ...definition, options: { ...definition.options, [option.optionField]: selectedOptionChoice.value } };
                          setColumnDefinitions(columnDefinitions.map((col, index) => index === itemIndex ? newColumnDef : col));
                        }}
                        placeholder="Select an option"
                        filteringType={option.searchable ? "auto" : "none"}
                      />
                    ) : option.type === ColumnControlType.Range ? (
                      <div className="flex gap-2">
                        <div className="grow">
                          <RangeDateSelector 
                            defaults={definition.options?.[option.optionField]}
                            onChange={(value) => {
                              const range = { ...value };
                              if (range.startDate) range.startDate = moment(range.startDate).format("YYYY-MM-DD");
                              if (range.endDate) range.endDate = moment(range.endDate).format("YYYY-MM-DD");
                              const newColumnDef = { ...definition, options: { ...definition.options, [option.optionField]: range } };
                              setColumnDefinitions(columnDefinitions.map((col, index) => index === itemIndex ? newColumnDef : col));
                              setLastDateRangeValue(range);
                            }}
                            isDateEnabled={(date: Date) => {
                              const isControlDateEnabled = option.isDateEnabled?.(date) ?? true;
                              if (!isControlDateEnabled) return false;
                              let startDate = option.bounds?.startDate ? moment(option.bounds.startDate) : moment("2020-01-01").startOf("day");
                              let endDate = option.bounds?.endDate ? moment(option.bounds.endDate) : moment().startOf("day");
                              if (option.bounds?.startDatePath) {
                                startDate = moment.utc(_.get(latestTimes, option.bounds.startDatePath) * 1000).startOf("day");
                              }
                              if (option.bounds?.endDatePath) {
                                endDate = moment.utc(_.get(latestTimes, option.bounds.endDatePath) * 1000).startOf("day");
                              }
                              const momentDate = moment(date).startOf("day");
                              return momentDate.isBetween(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), null, "[]");
                            }}
                          />
                        </div>
                        <Select
                          options={getExistingDateRangeOptions(option)}
                          selectedOption={null}
                          onChange={({ detail }) => {
                            const range = JSON.parse(detail.selectedOption.value);
                            const newColumnDef = { ...definition, options: { ...definition.options, [option.optionField]: range } };
                            setColumnDefinitions(columnDefinitions.map((col, index) => index === itemIndex ? newColumnDef : col));
                          }}
                          placeholder="Select existing range"
                          disabled={getExistingDateRangeOptions(option).length === 0}
                        />
                      </div>
                    ) : option.type === ColumnControlType.Date ? (
                      <div className="flex gap-2">
                        <DatePicker
                          value={definition.options?.[option.optionField]?.endDate ?? moment().format("YYYY-MM-DD")}
                          onChange={({ detail }) => {
                            const range = {
                              type: "absolute",
                              startDate: detail.value,
                              endDate: detail.value,
                            };
                            const newColumnDef = { ...definition, options: { ...definition.options, [option.optionField]: range } };
                            setColumnDefinitions(columnDefinitions.map((col, index) => index === itemIndex ? newColumnDef : col));
                            setLastDateValue(range);
                          }}
                          placeholder="YYYY/MM/DD"
                          openCalendarAriaLabel={() => "Select a date to fetch data from"}
                          isDateEnabled={(date: Date) => {
                            const isControlDateEnabled = option.isDateEnabled?.(date) ?? true;
                            if (!isControlDateEnabled) return false;
                            let startDate = option.bounds?.startDate ? moment(option.bounds.startDate) : moment("2020-01-01").startOf("day");
                            let endDate = option.bounds?.endDate ? moment(option.bounds.endDate) : moment().startOf("day");
                            if (option.bounds?.startDatePath) {
                              startDate = moment.utc(_.get(latestTimes, option.bounds.startDatePath) * 1000).startOf("day");
                            }
                            if (option.bounds?.endDatePath) {
                              endDate = moment.utc(_.get(latestTimes, option.bounds.endDatePath) * 1000).startOf("day");
                            }
                            const momentDate = moment(date).startOf("day");
                            return momentDate.isBetween(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), null, "[]");
                          }}
                        />
                        <Select
                          options={getExistingDateOptions(option)}
                          selectedOption={null}
                          onChange={({ detail }) => {
                            const range = JSON.parse(detail.selectedOption.value);
                            const newColumnDef = { ...definition, options: { ...definition.options, [option.optionField]: range } };
                            setColumnDefinitions(columnDefinitions.map((col, index) => index === itemIndex ? newColumnDef : col));
                          }}
                          placeholder="Select existing date"
                          disabled={getExistingDateOptions(option).length === 0}
                        />
                      </div>
                    ) : null}
                  </FormField>
                ))}
              </SpaceBetween>
            </BetterExpandableSection>
          )}
        </div>
      ) : (
        <div className={`mt-[-0.75rem] flex flex-col gap-2`}>
          {icons.attributes.length > 0 && (
            <div className="flex flex-wrap gap-2 text-xs">
              {icons.attributes.map(({ icon, text, hoverText=null }) => (
                <div className="relative inline-flex items-center flex-nowrap gap-0.5 border-2 border-gray-600/75 dark:text-gray-400 text-gray-600 rounded-md px-[0.5rem] py-[0.125rem]" title={hoverText}>
                  <div className="text-base w-5 h-5 flex items-center">{icon}</div>
                  {text && (<div className="text-nowrap">{text}</div>)}
                </div>
              ))}
            </div>
          )}
          <FormField description={<b>Applies to</b>}>
            <div className="flex flex-wrap gap-2 text-xs">
              {icons.verticals.map(({ icon, text, hoverText=null }) => (
                <div className="relative inline-flex items-center flex-nowrap gap-0.5 border-2 border-gray-600/75 dark:text-gray-400 text-gray-600 rounded-md px-[0.5rem] py-[0.125rem]" title={hoverText}>
                  <div className="text-base w-5 h-5 flex items-center">{icon}</div>
                  {text && (<div className="text-nowrap">{text}</div>)}
                </div>
              ))}
            </div>
          </FormField>
        </div>
      )}
    </Container>
  );
};