import { Icon, Link } from "@cloudscape-design/components";
import Popover from "@cloudscape-design/components/popover";
const RottenTomatesCriticScore = ({ rtCriticSummary, compact = false }) => {
  if (!rtCriticSummary || rtCriticSummary.score == null) {
    return (
      <div className="flex items-center space-x-2 mt-2">
        <img
          src="/rticons/tomatometer-empty.svg"
          alt="No Score"
          className={compact ? "h-6 w-6" : "h-12 w-12"}
        />
        <div className="text-black dark:text-slate-100 mt-2">
          <div className={`${compact ? 'text-lg' : 'text-xl font-bold'} dark:text-slate-50  leading-[1.25rem]`}>
            - %
          </div>
          {!compact && <div className="text-xs font-bold">TOMATOMETER</div>}
        </div>
      </div>
    );
  }

  const isFresh =
    rtCriticSummary.criticRating === "Fresh" ||
    rtCriticSummary.rating === "Fresh";
  const isCertifiedFresh =
    rtCriticSummary.criticRating === "Certified Fresh" ||
    rtCriticSummary.rating === "Certified Fresh";
  const isRotten =
    rtCriticSummary.criticRating === "Rotten" ||
    rtCriticSummary.rating === "Rotten";

  const score = rtCriticSummary.score;
  const count = rtCriticSummary.count || rtCriticSummary.numReviews;

  return (
    <div>
      <div className="flex items-center space-x-2">
        {isCertifiedFresh && (
          <img
            src="/rticons/certified_fresh.svg"
            alt="Fresh"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        )}
        {isFresh && score >= 75 && count > 80 && (
          <img
            src="/rticons/certified_fresh.svg"
            alt="Fresh"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        )}
        {isFresh && score < 75 && (
          <img
            src="/rticons/tomatometer-fresh.svg"
            alt="Fresh"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        )}
        {isFresh && score >= 75 && count < 80 && (
          <img
            src="/rticons/tomatometer-fresh.svg"
            alt="Fresh"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        )}
        {isRotten && (
          <img
            src="/rticons/tomatometer-rotten.svg"
            alt="Rotten"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        )}
        <div className="text-black dark:text-slate-100">
          <div className={`${compact ? 'text-md' : 'text-lg font-bold'} dark:text-slate-50 leading-[1.25rem]`}>
            {score ? `${score}%` : "N/A"}
          </div>
          {!compact && (
            <>
              {rtCriticSummary.url ? (
                <Link 
                  href={rtCriticSummary.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <div className="text-xs font-bold">Tomatometer <Icon name="external" size="inherit" /></div>
                </Link>
              ) : (
                <div className="text-xs font-bold">Tomatometer</div>
              )}
            </>
          )}
          <div className="flex items-center">
            {rtCriticSummary.consensus ? (
              <Popover
                header="Critic Consensus"
                dismissButton={false}
                position="right"
                triggerType="text"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rtCriticSummary.consensus,
                    }}
                  />
                }
              >
                <div className="text-xs italic dark:text-slate-400">
                  {`${count} reviews`}
                </div>
              </Popover>
            ) : (
              <div className="text-xs italic dark:text-slate-400">
                {`${count} reviews`}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RottenTomatesCriticScore;
