import { useEffect, useState } from "react";
import { useGetTrackedRankingData, UseGetTrackedRankingProps } from "./useGetTrackedRankingData";
import { useDeferredPromise } from "../../../../../hooks/useDeferredPromise";


export const useGetTrackedRankingDataPromise = () => {

  const [requestParams, setRequestParams] = useState({});

  const { data, isLoading, error } = useGetTrackedRankingData(requestParams as UseGetTrackedRankingProps);
  const { defer, deferRef } = useDeferredPromise();

  useEffect(() => {
    if (!isLoading) {
      setRequestParams({});
      if (error) {
        deferRef.reject(error);
      } else if (data) {
        deferRef.resolve(data);
      }
    }
  }, [data, isLoading, error]);

  const getTrackedRankingData = async (params: UseGetTrackedRankingProps) => {
    setRequestParams(params);
    return defer().promise;
  };

  return { getTrackedRankingData, isLoading, error };
};